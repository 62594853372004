import { Layout, Card, NavigationBar } from "@careevolution/mydatahelps-ui"
import CHSW55Tasks from "../CHSW55Tasks/CHSW55Tasks";

export default function () {
  return (
    <Layout colorScheme="light">
      <NavigationBar showBackButton={true} />
      <Card>
        <CHSW55Tasks
          status="incomplete"
          title={'Tasks'}
        />
      </Card>
    </Layout>
  )
}