import { BrowserRouter, Route, Routes } from 'react-router-dom'
import Dashboard from './Dashboard/Dashboard'
import Training from './Training/Training'
import MyData from './MyData/MyData'
import FFWELReport from './FFWELReport/FFWELReport';
import InitialAssessmentReport from './InitialAssessmentReport/InitialAssessmentReport';
import TasksView from './TasksView/TasksView';
import Booking from './Booking/Booking';
import ParticipantAdministrationReport from './ParticipantAdministrationReport/ParticipantAdministrationReport';
import GoalHub from './GoalHub/GoalHub';
import SuggestedGoals from './GoalHub/SuggestedGoals';
import ReviewSMARTResults from './GoalHub/ReviewSMARTResults';

function App() {

  return (
      <BrowserRouter>
          <Routes>
            <Route path="/" element={<Dashboard/>}/>
            <Route path="/Tasks" element={<TasksView />} />
            <Route path="/Training" element={<Training />}/>
            <Route path="/MyData" element={<MyData />}/>
            <Route path="/FFWELReport" element={<FFWELReport />}/>
            <Route path="/InitialAssessmentReport" element={<InitialAssessmentReport />}/>
            <Route path="/ParticipantAdministrationReport" element={<ParticipantAdministrationReport />}/>
            <Route path="/Booking" element={<Booking />} />
            <Route path="/GoalHub" element={<GoalHub />} />
            <Route path="/SuggestedGoals" element={<SuggestedGoals />} />
            <Route path="/ReviewSMARTResults" element={<ReviewSMARTResults />} />
          </Routes>
      </BrowserRouter>
  )
}

export default App
