import { FontAwesomeSvgIcon } from 'react-fontawesome-svg-icon';
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import './TableCarousel.css';
import { useState } from 'react';

interface TableCarouselProps {
	pages: React.ReactNode[]
}

export default function (props: TableCarouselProps) {
	const [selectedPageIndex, setSelectedPageIndex] = useState(0);

	const previousPage = () => {
		var newIndex = selectedPageIndex - 1;
		if (newIndex < 0) {
			newIndex = props.pages.length - 1;
		}

		setSelectedPageIndex(newIndex);
	}

	const nextPage = () => {
		var newIndex = selectedPageIndex + 1;
		if (newIndex === props.pages.length) {
			newIndex = 0;
		}

		setSelectedPageIndex(newIndex);
	}


	return (
		<div className='carousel'>
			<FontAwesomeSvgIcon className='navigation-arrow' icon={faChevronLeft} onClick={previousPage} />
			{/*props.pages.map((page, index) => {
				if (index === selectedPageIndex) {
					return page;
				}
				return null;
			}
		)*/}
			{props.pages.map((page, index) => {
				return <div className='carousel-page' style={{ display: index === selectedPageIndex ? 'block' : 'none' }}>
					{page}
				</div>;
			})}
			<FontAwesomeSvgIcon className='navigation-arrow' icon={faChevronRight} onClick={nextPage} />
		</div>
	);
}