import MyDataHelps, { ParticipantInfo } from "@careevolution/mydatahelps-js";
import ReportCard from "./ReportCard";
import { useEffect, useState } from "react";
import parseISO from "date-fns/parseISO";

interface PSQIReportCardProps {
    participantInfo: ParticipantInfo
}

export default function (props: PSQIReportCardProps) {
    let [loading, setLoading] = useState(true);
    let [lastTaken, setLastTaken] = useState<Date>();

    function initialize() {
        setLoading(true);
        MyDataHelps.querySurveyAnswers({
            surveyName: 'WHODAS',
            limit: 1
        }).then((lastSurveyResult) => {
            if (lastSurveyResult.surveyAnswers.length) {
                setLastTaken(parseISO(lastSurveyResult.surveyAnswers[0].date))
            }
            setLoading(false);
        });
    }

    useEffect(() => {
        initialize()
        MyDataHelps.on("applicationDidBecomeVisible", initialize);
        return () => {
            MyDataHelps.off("applicationDidBecomeVisible", initialize);
        }
    }, []);

    function getInterpretation() {
        var sleepValue = parseInt(props.participantInfo.customFields['PSQI_score']);
        if (sleepValue > 80) {
            return "Extreme difficulty";
        }
        else if (sleepValue >= 60) {
            return "Severe difficulty";
        }
        else if (sleepValue >= 40) {
            return "Moderate difficulty";
        }
        else if (sleepValue >= 20) {
            return "Mild difficulty";
        }
        else {
            return "No difficulty"
        }
    }

    return (
        <ReportCard label="Functional Ability Score"
            loading={loading}
            lastTaken={lastTaken}
            score={props.participantInfo.customFields['WHODAS_score']}
            interpretation={getInterpretation()}
        />
    );
}