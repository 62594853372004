import { useEffect, useState } from 'react';
import './MyData.css'
import MyDataHelps, { ParticipantInfo } from '@careevolution/mydatahelps-js';
import { Card, DateRangeNavigator, Layout, LoadingIndicator, checkDailyDataAvailability } from '@careevolution/mydatahelps-ui';
import { DailyDataType } from '@careevolution/mydatahelps-ui';
import WearableAverage from './WearableAverage';
import FFWELReportCard from './ReportCards/FFWELReportCard';
import PQSIReportCard from './ReportCards/PQSIReportCard';
import generateRoute from '../utilities/generateRoute';
import CHSDeviceDataMonthChart from '../CHSDeviceDataMonthChart/CHSDeviceDataMonthChart';
import WHODASReportCard from './ReportCards/WHODASReportCard';
import TableCarousel from './TableCarousel/TableCarousel';
import CHSSurveyAnswerMonthChart from '../CHSSurveyAnswerMonthChart/CHSSurveyAnswerMonthChart';

interface ChartSetup {
	dailyDataType: DailyDataType,
	chartLabel: string,
	summaryCaption: string,
	order: number,
	valueConverter?: Function,
	yAxisLabel?: string,
	xAxisLabel?: string,
	yAxisRange?: [string | number, string | number],
	xAxisRange?: [string | number, string | number],
}

export default function () {
	const [loading, setLoading] = useState(true);
	const [participantInfo, setParticipantInfo] = useState<ParticipantInfo | null>(null);
	const [availableData, setAvailableData] = useState<ChartSetup[]>([]);

	var currentDate = new Date();
	const [intervalStart, setIntervalStart] = useState(new Date(currentDate.getFullYear(), currentDate.getMonth(), 1, 0, 0, 0, 0));

	function updateInterval(intervalStart: Date) {
		setIntervalStart(intervalStart);
	}

	useEffect(() => {
		initialize()
		MyDataHelps.on("applicationDidBecomeVisible", initialize);
		return () => {
			MyDataHelps.off("applicationDidBecomeVisible", initialize);
		}
	},[]);

	function minutesToHours(minutes: number) { 
		return parseFloat((minutes / 60).toFixed(2));
	}

	function initialize() {
		var loadData = function () {
			let dataChecks = [];
			let foundData: ChartSetup[] = [];
			const possibleChartGraphs: ChartSetup[] = [
				{
					dailyDataType: DailyDataType.FitbitSleepMinutes,
					chartLabel: "Sleep",
					summaryCaption: "hrs",
					order: 3,
					valueConverter: minutesToHours,
					yAxisLabel: 'Hours',
					xAxisLabel: 'Date'
				},
				{
					dailyDataType: DailyDataType.AppleHealthSleepMinutes,
					chartLabel: "Sleep",
					summaryCaption: "hrs",
					order: 3,
					valueConverter: minutesToHours,
					yAxisLabel: 'Hours',
					xAxisLabel: 'Date'
				},
				{
					dailyDataType: DailyDataType.GarminTotalSleepMinutes,
					chartLabel: "Sleep",
					summaryCaption: "hrs",
					order: 3,
					valueConverter: minutesToHours,
					yAxisLabel: 'Hours',
					xAxisLabel: 'Date'
				},
				{
					dailyDataType: DailyDataType.FitbitCaloriesBurned,
					chartLabel: "Calories Burned",
					summaryCaption: "cal",
					order: 0,
					yAxisLabel: 'Calories',
					xAxisLabel: 'Date'
				},
				{
					dailyDataType: DailyDataType.FitbitRestingHeartRate,
					chartLabel: "Resting Heart Rate",
					summaryCaption: "bpm",
					order: 2,
					yAxisLabel: "BPM",
					yAxisRange: [40,100],
					xAxisLabel: 'Date'
				},
				{
					dailyDataType: DailyDataType.AppleHealthRestingHeartRate,
					chartLabel: "Resting Heart Rate",
					summaryCaption: "bpm",
					order: 2,
					yAxisLabel: "BPM",
					yAxisRange: [40,100],
					xAxisLabel: 'Date'
				},
				{
					dailyDataType: DailyDataType.GarminRestingHeartRate,
					chartLabel: "Resting Heart Rate",
					summaryCaption: "bpm",
					order: 2,
					yAxisLabel: "BPM",
					yAxisRange: [40,100],
					xAxisLabel: 'Date'
				},
				{
					dailyDataType: DailyDataType.FitbitSteps,
					chartLabel: "Steps",
					summaryCaption: "steps",
					order: 1,
					yAxisLabel: "Steps",
					xAxisLabel: 'Date'
				},
				{
					dailyDataType: DailyDataType.AppleHealthSteps,
					chartLabel: "Steps",
					summaryCaption: "steps",
					order: 1,
					yAxisLabel: "Steps",
					xAxisLabel: 'Date'
				},
				{
					dailyDataType: DailyDataType.GarminSteps,
					chartLabel: "Steps",
					summaryCaption: "steps",
					order: 1,
					yAxisLabel: "Steps",
					xAxisLabel: 'Date'
				}
			];
			possibleChartGraphs.forEach(type => {
				dataChecks.push(
					checkDailyDataAvailability(type.dailyDataType).then((available) => {
						if (available) {
							foundData.push(type);
						}
					}).catch((_reason) => {})
				)
			});

			dataChecks.push(MyDataHelps.getParticipantInfo().then(function (result) {
				setParticipantInfo(result);
			}));

			Promise.all(dataChecks).then(() => {
				setAvailableData(foundData.sort((a,b) => a.order-b.order));
				setLoading(false);
			})
		}
		loadData();
	}

	if (loading) {
		return (
			<Layout colorScheme='light'><LoadingIndicator/></Layout>
		)
	}

	var month = intervalStart.getMonth();
	var year = intervalStart.getFullYear();

	return (
		<Layout className="chsw55-my-data" colorScheme="light">
			<div className='section-header'>Surveys</div>
			<Card className='summary-row'>
				<FFWELReportCard participantInfo={participantInfo!} />
				<WHODASReportCard participantInfo={participantInfo!}/>
				<PQSIReportCard participantInfo={participantInfo!}/>
			</Card>
			<Card>
				<div className='assessment-details-card'>
					<p>For more info about your assessments:</p>
					<div className='assessment-details-links'>
						<button className='mdhui-button' onClick={() => MyDataHelps.openApplication(generateRoute('/FFWELReport'), { modal: true })}>
							Wellness Scores
						</button>
						<button className='mdhui-button' onClick={() => MyDataHelps.openApplication(generateRoute('/InitialAssessmentReport'), { modal: true })}>
							Initial Assessments
						</button>
					</div>
				</div>
			</Card>
			<Card>
				<TableCarousel pages={[
					<CHSSurveyAnswerMonthChart title='Wellness Scores' xAxisLabelBarChart='Wellness Factors' xAxisLabel='Date Taken' yAxisLabel='Score' lines={
						[
							{ label: "Total Wellness", surveyName: "FFWEL", stepIdentifier: "TotalWellness", resultIdentifier: "TotalWellness", stroke: "#e41a1c" },
							{ label: "Creative Self", surveyName: "FFWEL", stepIdentifier: "CreativeSelf", resultIdentifier: "CreativeSelf", stroke: "#377eb8" },
							{ label: "Coping Self", surveyName: "FFWEL", stepIdentifier: "CopingSelf", resultIdentifier: "CopingSelf", stroke: "#4daf4a" },
							{ label: "Social Self", surveyName: "FFWEL", stepIdentifier: "SocialSelf", resultIdentifier: "SocialSelf", stroke: "#984ea3" },
							{ label: "Essential Self", surveyName: "FFWEL", stepIdentifier: "EssentialSelf", resultIdentifier: "EssentialSelf", stroke: "#ff7f00" },
							{ label: "Physical Self", surveyName: "FFWEL", stepIdentifier: "PhysicalSelf", resultIdentifier: "PhysicalSelf", stroke: "#a65628" }
						]} />,
					<CHSSurveyAnswerMonthChart title="Functional Ability Score" xAxisLabelBarChart='Functional Domain' xAxisLabel='Date Taken' yAxisLabel='Score' lines={
						[
							{ label: "Overall", surveyName: "WHODAS", stepIdentifier: "WHODAS_score", resultIdentifier: "WHODAS_score", stroke: "#e41a1c" },
							{ label: "Thinking", surveyName: "WHODAS", stepIdentifier: "cognition_score", resultIdentifier: "cognition_score", stroke: "#377eb8" },
							{ label: "Mobility", surveyName: "WHODAS", stepIdentifier: "mobility_score", resultIdentifier: "mobility_score", stroke: "#4daf4a" },
							{ label: "Self Care", surveyName: "WHODAS", stepIdentifier: "selfCare_score", resultIdentifier: "selfCare_score", stroke: "#984ea3" },
							{ label: "Getting Along", surveyName: "WHODAS", stepIdentifier: "gettingAlong_score", resultIdentifier: "gettingAlong_score", stroke: "#ff7f00" },
							{ label: "Life Activities", surveyName: "WHODAS", stepIdentifier: "lifeActivities_score", resultIdentifier: "lifeActivities_score", stroke: "#a65628" },
							{ label: "Participation in Society", surveyName: "WHODAS", stepIdentifier: "participationInSociety_score", resultIdentifier: "participationInSociety_score", stroke: "#f781bf" }
						]} />,
					<CHSSurveyAnswerMonthChart title="Sleep Quality Score" xAxisLabel='Date Taken' yAxisLabel='Score' lines={
						[
							{ label: "Overall", surveyName: "PSQI", stepIdentifier: "PSQI_score", resultIdentifier: "PSQI_score", stroke: "green" },
						]} />
				]}/>

			</Card>
			<div className='section-header'>Wearable Data</div>
			<Card>
				<DateRangeNavigator intervalStart={intervalStart} intervalType='Month' onIntervalChange={updateInterval} />
				<div className='summary-row'>
					<div>
						Averages:
					</div>
					{availableData.map(line => {
						return <WearableAverage key={line.dailyDataType} label={line.chartLabel} type={line.dailyDataType} month={month} year={year} formatter={(average: string) => `${average} ${line.summaryCaption}`} valueConverter={line.valueConverter} />
					})
					}
				</div>
				<div className='summary-row'>
					<TableCarousel pages={availableData.map((line) => {
							return <CHSDeviceDataMonthChart key={line.dailyDataType} lines={
								[{
									showAverage: true,
									dailyDataType: line.dailyDataType,
									label: line.chartLabel,
									valueConverter: line.valueConverter
								}]}
								month={month}
								year={year}
								title={line.chartLabel}
								yAxisLabel={line.yAxisLabel}
								xAxisLabel={line.xAxisLabel}
								yAxisRange={line.yAxisRange}
								xAxisRange={line.yAxisRange}
							/>
					})}/>
				</div>
			</Card>
		</Layout>
	);
}