import { useState, useEffect, useRef } from 'react'
import MyDataHelps, { SurveyAnswersPage } from "@careevolution/mydatahelps-js"
import { Label, LineChart, Line, ResponsiveContainer, CartesianGrid, Tooltip, XAxis, YAxis, Legend, BarChart, Bar, Cell } from 'recharts'
import { LoadingIndicator } from '@careevolution/mydatahelps-ui'
import parseISO from 'date-fns/parseISO'

export interface SurveyAnswerChartLine {
	label: string;
	surveyName: string;
	stepIdentifier: string;
	resultIdentifier: string;
	valueConverter?: Function;
	stroke: string;
}

export interface CHSSurveyAnswerChartProps {
	lines: SurveyAnswerChartLine[],
	syncId?: string,
	title?: string,
	yAxisLabel?: string,
	xAxisLabel?: string,
	xAxisLabelBarChart?: string,
	yAxisRange?: [string|number, string|number],
	xAxisRange?: [string|number, string|number],
	onDataDetected?: Function
}

interface TickProps {
	x: number,
	y: number,
	stroke: any,
	payload: any
}

function DayTick(props: TickProps) {
	const { x, y, payload } = props;
	var value = payload.value;
	return (
		<text x={x} y={y + 15} textAnchor="middle" fontSize="12">{value}</text>
	);
}

export default function (props: CHSSurveyAnswerChartProps) {
	const [dailyData, setDailyData] = useState<{ [key: string]: SurveyAnswersPage } | null>(null);
	const [loading, setLoading] = useState(false);

	var currentInitialization = useRef<number>();

	function getDataKey(line: SurveyAnswerChartLine) {
		return `${line.surveyName}-${line.stepIdentifier}-${line.resultIdentifier}`;
	}

	function initialize() {
		currentInitialization.current = (currentInitialization.current ?? 0) + 1;
		var initialization = currentInitialization.current ?? 0;
		setLoading(true);
		var loadData = function () {
			var dataRequests = props.lines.map(l => MyDataHelps.querySurveyAnswers({surveyName: l.surveyName, stepIdentifier: l.stepIdentifier, resultIdentifier: l.resultIdentifier}));
			Promise.all(dataRequests).then(function (data) {
				if (initialization != currentInitialization.current) {
					return;
				}
				var newDailyData: { [key: string]: SurveyAnswersPage } = {};
				for (var i = 0; i < props.lines.length; i++) {
					newDailyData[getDataKey(props.lines[i])] = data[i];
				}
				setDailyData(newDailyData);
				setLoading(false);
			})
		}

		loadData();
	}

	useEffect(() => {
		initialize();
		MyDataHelps.on("applicationDidBecomeVisible", initialize);
		MyDataHelps.on("externalAccountSyncComplete", initialize);

		return () => {
			MyDataHelps.off("applicationDidBecomeVisible", initialize);
			MyDataHelps.off("externalAccountSyncComplete", initialize);
		}
	}, [props]);

	var data: any[] = [];
	var graphHasData = false;
	var showBarGraph = false;

	if (dailyData) {
		props.lines.forEach((line) => {
			var dataKey = getDataKey(line);
			dailyData[dataKey].surveyAnswers.forEach((answer) => {
				var day = parseISO(answer.date).toLocaleDateString();
				var dataDay = data.find(d => d.day === day);
				if (!dataDay) {
					dataDay = {
						day
					};
					data.push(dataDay);
				}
				dataDay[line.label] = parseFloat(answer.answers[0]);
				graphHasData = true;
			});
		});

		data.sort((a, b) => new Date(a.day).getTime() - new Date(b.day).getTime());

		if (data.length === 1) {
			showBarGraph = true;
			var newData: any[] = [];
			var keys = Object.keys(data[0]).filter(k => k !== 'day');
			keys.forEach(key => newData.push({
				key,
				value: data[0][key]
			}));
			data = newData;
		}
	}

	const GraphToolTip = ({ active, payload }: any) => {
		if (active && payload && payload.length) {
			return (
				<div className="graph-tooltip">
					<div className="graph-date">{payload[0].payload.day}</div>
					<table className="payload-values">
						<tbody>
							{payload.map((p: any) =>
								<tr key={p.dataKey}>
									<th style={{color: p.stroke}}>{p.dataKey}</th>
									<td>{parseFloat(p.value).toFixed(2)}</td>
								</tr>
							)}
						</tbody>
					</table>
				</div>
			);
		}

		return null;
	};

	function tickFormatter(args: any) {
		if (args >= 10000) {
			return Math.floor(args / 1000) + 'K';
		}
		return args;
	}

  const [isDesktop, setDesktop] = useState(window.innerWidth > 640);

  const updateMedia = () => {
    setDesktop(window.innerWidth > 640);
  };

  useEffect(() => {
    window.addEventListener("resize", updateMedia);
    return () => window.removeEventListener("resize", updateMedia);
  });	

	return (
		<div className="mdhui-device-data-month-chart">
			{props.title &&
				<div className="title">
					{props.title}
				</div>
			}
			<div style={{ clear: "both" }}></div>
			<div className="chart-container" style={{ height: 'auto' }}>
				{(!graphHasData || loading) &&
					<div>
						{!graphHasData && !loading &&
							<div className="no-data-label">No Data</div>
						}
						{loading &&
							<LoadingIndicator />
						}
						<ResponsiveContainer width={'100%'} height={250}>
							<LineChart width={400} height={400} data={data} syncId={props.syncId}>
								<CartesianGrid strokeDasharray="3 3" />
								<YAxis type="number" axisLine={false} interval={0} tickLine={false} width={60} scale="linear"
									domain={props.yAxisRange || ['auto', 'auto']}
								>
									{props.yAxisLabel &&
										<Label value={props.yAxisLabel} angle={-90} dx={-20}></Label>
									}
								</YAxis>
								<XAxis id="myXAxis" tick={DayTick} axisLine={false} dataKey="day" tickMargin={0} minTickGap={0} tickLine={false} interval={1} />
							</LineChart>
						</ResponsiveContainer>
					</div>
				}
				{graphHasData && !loading &&
					<>
						{!showBarGraph &&
							<ResponsiveContainer width={'100%'} height={250}>
								<LineChart width={400} height={400} data={data} syncId={props.syncId}>
									{props.lines.map((line) =>
										<Line key={getDataKey(line)} type="monotone" dataKey={line.label} stroke={line.stroke || "var(--mdhui-color-primary)"} />
									)}
									<Tooltip content={<GraphToolTip />} />
									<CartesianGrid strokeDasharray="3 3" />
									<YAxis tickFormatter={tickFormatter} axisLine={false} interval={0} tickLine={false} width={60}
										domain={props.yAxisRange || ['auto', 'auto']}
									>
										{props.yAxisLabel &&
											<Label value={props.yAxisLabel} angle={-90} dx={-20}></Label>
										}
									</YAxis>
									<XAxis id="myXAxis" tick={DayTick} axisLine={false} dataKey="day" tickMargin={0} minTickGap={0} tickLine={false} interval={1} height={40}>
										{props.xAxisLabel &&
											<Label value={props.xAxisLabel} dy={15}></Label>
										}
									</XAxis>
									{isDesktop &&
										<Legend layout='vertical' align='right' verticalAlign='top' wrapperStyle={{ right: -5, fontSize: 14 }} />
									}
									{!isDesktop &&
										<Legend layout='horizontal' align='center' wrapperStyle={{ fontSize: 12 }} iconSize={12} />
									}
								</LineChart>
							</ResponsiveContainer>
						}
						{showBarGraph &&
							<ResponsiveContainer width={'100%'} height={250}>
								<BarChart width={400} height={400} data={data} syncId={props.syncId}>
									<Tooltip></Tooltip>
									<CartesianGrid strokeDasharray="3 3"/>
									<YAxis type="number" tickFormatter={tickFormatter} axisLine={false} interval={0} tickLine={false} width={60}
										domain={props.yAxisRange || ['auto', 'auto']}>
										{props.yAxisLabel &&
											<Label value={props.yAxisLabel} angle={-90} dx={-20}></Label>
										}
									</YAxis>
									<XAxis type="category" id="myXAxis" dataKey="key" height={40}>
										{props.xAxisLabelBarChart &&
											<Label value={props.xAxisLabelBarChart} dy={15}></Label>
										}
									</XAxis>
									<Bar dataKey="value">
										{props.lines.map((line) =>
											<Cell key={line.label} fill={line.stroke || "var(--mdhui-color-primary)"} />
										)}
									</Bar>
								</BarChart>
							</ResponsiveContainer>
						}
					</>
				}
			</div>
		</div>
	);
}