import { Layout, NavigationBar } from "@careevolution/mydatahelps-ui"
import './Booking.css'

export default function () {
  return (
    <Layout colorScheme="light">
      <style>{`
        html,
        body {
          height: 100%;
        }

        #root {
          height: 100%;
          display: flex;
          flex-direction: column;
        }

        .mdhui-layout {
          flex: 1 0 auto;
          display: flex;
          flex-direction: column;
        }
      `}</style>
      <NavigationBar showCloseButton={true} />
      <iframe className="booking-frame"
        src='https://outlook.office365.com/owa/calendar/Wellness551@canhs.ca/bookings/'
        scrolling='yes'
        style={{ border: 0 }}></iframe>
    </Layout>
  )
}