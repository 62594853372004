import { Layout, StatusBarBackground, ProjectHeader, Card, MostRecentNotification, ConnectFitbit, ConnectGarmin, ProjectSupport, ConnectEhr, Action } from "@careevolution/mydatahelps-ui"
import MyDataHelps, { NotificationType } from "@careevolution/mydatahelps-js"
import CHSW55Tasks from "../CHSW55Tasks/CHSW55Tasks";
import generateRoute from "../utilities/generateRoute";

export interface HomeViewProps {
  /** 
   * Notification Type to display for the most recent notification
   * @default Push
   * */
  notificationType?: "Sms" | "Push";
  /** Hides the most recent notification after a certain number of hours of it being sent */
  notificationHideAfterHours?: number
  /** Hide the task due date on displayed tasks */
  hideTaskDueDate?: boolean
  /** When present, causes the EHR connect widget to display. */
  ehrConnectApplicationUrl?: string
  notificationsViewUrl?: string
  tasksViewUrl?: string
}

export default function (props: HomeViewProps) {
  var notificationType: NotificationType = "Push";
  if (props.notificationType) {
    notificationType = props.notificationType;
  }

  var viewAllNotifications = function () {
    if (props.notificationsViewUrl) {
      MyDataHelps.openApplication(props.notificationsViewUrl);
    }
  }

  var viewAllTasks = function () {
    MyDataHelps.openApplication(generateRoute('/Tasks'));
  }

  var openFitbitHelp = function () {
    MyDataHelps.openExternalUrl('https://myhelp.fitbit.com/s/home?language=en_US&co=CA');
  }

  return (
    <Layout colorScheme="light">
      <StatusBarBackground color='#FFFFFF' />
      <ProjectHeader />
      {/*
      <AppDownload previewDevicePlatform={props.preview ? 'Web' : undefined} previewProjectPlatforms={props.preview ? ['Web', 'Android', 'iOS'] : undefined} />
      */}
      <Card>
        <MostRecentNotification
          notificationType={notificationType}
          onViewMore={props.notificationsViewUrl ? () => viewAllNotifications() : undefined}
          hideAfterHours={props.notificationHideAfterHours} />
      </Card>
      <Card>
        <CHSW55Tasks
          status="incomplete"
          title={'Tasks'}
          onDetailLinkClick={viewAllTasks}
          limit={3}
        />
      </Card>
      <Card>
        <Action
          onClick={() => MyDataHelps.startSurvey('upcomingEvents')}
          title="Upcoming Events">
        </Action>
      </Card>
      <Card>
        <Action
          onClick={() => MyDataHelps.openApplication(generateRoute('/Booking'), { modal: true })}
          title="Book an Appointment"
          subtitle="Click here to schedule some time to meet with a member of the Wellness 55 Team for goals coaching or assistance!"
        ></Action>
      </Card>
      <Card>
        <ConnectFitbit title="Fitbit" />
        <Action title="Need help with your Fitbit?" onClick={openFitbitHelp}/>
      </Card>
      <Card>
        <ConnectGarmin title="Garmin" />
      </Card>
      <Card>
        {props.ehrConnectApplicationUrl &&
          <ConnectEhr applicationUrl={props.ehrConnectApplicationUrl} />
        }
      </Card>
      <Card>
        <Action
          onClick={() => MyDataHelps.startSurvey('feedbackSurvey')}
          title="Feedback"
          subtitle="Let us know what you think!"
        >
        </Action>
      </Card>
      <Card>
        <ProjectSupport />
      </Card>
    </Layout>
  )
}