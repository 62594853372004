import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import App from './App'
import MyDataHelps from '@careevolution/mydatahelps-js';

if (
  import.meta.env.DEV &&
  import.meta.env.VITE_MDH_PARTICIPANT_ACCESS_TOKEN &&
  import.meta.env.VITE_MDH_BASE_URL
) {
  MyDataHelps.setParticipantAccessToken(
    {
      access_token: import.meta.env.VITE_MDH_PARTICIPANT_ACCESS_TOKEN,
      expires_in: 21600,
      token_type: "Bearer",
    },
    import.meta.env.VITE_MDH_BASE_URL,
  );
}

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <React.StrictMode>
    <App/>
  </React.StrictMode>,
)
