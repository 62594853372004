import { FiveFWELResults } from "./FFWELReportInterface";
import './FFWELReport.css';

interface FFWELBreakdownTableProps {
    model: FiveFWELResults;
    showInterpretation?: boolean;
    showReference?: boolean;
}

export default function (props: FFWELBreakdownTableProps) {

    return (
        <table>
            <tr>
                <th className='factor' rowSpan={props.showReference ? 2 : 1}>Factor</th>
                <th className='score' rowSpan={props.showReference ? 2 : 1}>Score</th>
                {props.showInterpretation &&
                    <th className='interpretation'>Interpretation</th>
                }
                {props.showReference &&
                        <>
                            <th colSpan={3}>Reference (N = 6733)</th>
                            <th rowSpan={props.showReference ? 2 : 1}>Optimal Wellness Delta (90+)</th>
                        </>
                    }
            </tr>
                {props.showReference &&
                    <tr>
                        <th>Mean</th>
                        <th>SD</th>
                        <th>Cut-off (2 SD Below Mean)</th>
                </tr>
                }
            <tr>
                <th colSpan={props.showReference ? 6 : 3}>Creative Self</th>
            </tr>
            <tr>
                <td>
                    Thinking
                </td>
                <td className={props.model.thinking.coding}>
                    {props.model.thinking.score.toFixed(2)}
                </td>
                {props.showInterpretation &&
                    <td>
                        <ul>
                            <li>Being mentally active</li>
                            <li>Being open-minded</li>
                            <li>The ability to be creative and experimental</li>
                            <li>Having a sense of curiosity</li>
                            <li>Being able to apply problem-solving strategies</li>
                        </ul>

                    </td>
                }
                    {props.showReference &&
                    <><td>79.04</td><td>11.31</td><td>56.42</td><td>{(90 - props.model.thinking.score).toFixed(2)}</td></>
                    }
            </tr>
            <tr>
                <td>
                    Emotions
                </td>
                <td className={props.model.emotions.coding}>
                    {props.model.emotions.score.toFixed(2)}
                </td>
                {props.showInterpretation &&
                    <td>
                        <ul>
                            <li>Being aware of or in touch with our feelings</li>
                            <li>The ability to appropriately express our feelings</li>
                        </ul>
                        </td>
                    }
                    {props.showReference &&
                        <>
                        <td>79.79</td>
                        <td>11.93</td>
                        <td>55.93</td>
                        <td>{(90 - props.model.emotions.score).toFixed(2)}</td>
                        </>
                    }
                    </tr>
            <tr>
                <td>
                    Control
                </td>
                <td className={props.model.control.coding}>
                    {props.model.control.score.toFixed(2)}
                </td>
                {props.showInterpretation &&
                    <td>
                        <ul>
                            <li>Beliefs about our competence, confidence, and personal mastery</li>
                            <li>Beliefs that we can usually achieve the goals we set out for ourselves</li>
                        </ul>
                    </td>
                }
                    {props.showReference &&
                        <>
                        <td>80.71</td>
                        <td>11.30</td>
                        <td>58.11</td>
                        <td>{(90 - props.model.control.score).toFixed(2)}</td>
                        </>
                    }
 
            </tr>
            <tr>
                <td>
                    Work
                </td>
                <td className={props.model.work.coding}>
                    {props.model.work.score.toFixed(2)}
                </td>
                {props.showInterpretation &&
                    <td>
                        <ul>
                            <li>Being satisfied with our work</li>
                            <li>Feeling that our skills are appropriately used</li>
                            <li>Feeling that we can manage our workload</li>
                            <li>Feeling a sense of job security</li>
                            <li>Feeling appreciated in the work we do</li>
                        </ul>
                    </td>
                }
                    {props.showReference &&
                        <>
                        <td>74.44</td>
                        <td>13.08</td>
                        <td>48.28</td>
                        <td>{(90 - props.model.work.score).toFixed(2)}</td>
                        </>
                    }
 
            </tr>
            <tr>
                <td>
                    Positive Humour
                </td>
                <td className={props.model.positiveHumor.coding}>
                    {props.model.positiveHumor.score.toFixed(2)}
                </td>
                {props.showInterpretation &&
                    <td>
                        <ul>
                            <li>Being able to laugh at our mistakes</li>
                            <li>The ability to use humour to accomplish tasks</li>
                        </ul>
                    </td>
                }
                    {props.showReference &&
                        <>
                        <td>80.91</td>
                        <td>12.44</td>
                        <td>56.03</td>
                        <td>{(90 - props.model.positiveHumor.score).toFixed(2)}</td>
                        </>
                    }
 
            </tr>
            <tr>
                <th colSpan={props.showReference ? 6 : 3}>Coping Self</th>
            </tr>
            <tr>
                <td>
                    Leisure
                </td>
                <td className={props.model.leisure.coding}>
                    {props.model.leisure.score.toFixed(2)}
                </td>
                {props.showInterpretation &&
                    <td>
                        <ul>
                            <li>Being satisfied with our recreation and leisure time activities</li>
                            <li>Having at least one activity in which you lose yourself and feel a sense of flow</li>
                        </ul>
                    </td>
                }
                    {props.showReference &&
                        <>
                        <td>78.00</td>
                        <td>13.34</td>
                        <td>51.32</td>
                        <td>{(90 - props.model.leisure.score).toFixed(2)}</td>
                        </>
                    }
 
            </tr>
            <tr>
                <td>
                    Self-Worth
                </td>
                <td className={props.model.selfWorth.coding}>
                    {props.model.selfWorth.score.toFixed(2)}
                </td>
                {props.showInterpretation &&
                    <td>
                        <ul>
                            <li>Accepting who and what one is, our positive qualities along with our imperfections</li>
                            <li>A sense of being genuine within oneself and with others</li>
                        </ul>
                    </td>
                }
                    {props.showReference &&
                        <>
                        <td>82.50</td>
                        <td>16.12</td>
                        <td>50.26</td>
                        <td>{(90 - props.model.selfWorth.score).toFixed(2)}</td>
                        </>
                    }
 
            </tr>
            <tr>
                <td>
                    Realistic Beliefs
                </td>
                <td className={props.model.realisticBeliefs.coding}>
                    {props.model.realisticBeliefs.score.toFixed(2)}
                </td>
                {props.showInterpretation &&
                    <td>
                        <ul>
                            <li>The ability to process information and perceive reality accurately</li>
                            <li>Lack of unrealistic beliefs</li>
                        </ul>
                    </td>
                }
                    {props.showReference &&
                        <>
                        <td>57.96  </td>
                        <td>13.63</td>
                        <td>30.70</td>
                        <td>{(90 - props.model.realisticBeliefs.score).toFixed(2)}</td>
                        </>
                    }
 
            </tr>
            <tr>
                <td>
                    Stress Management
                </td>
                <td className={props.model.stressManagement.coding}>
                    {props.model.stressManagement.score.toFixed(2)}
                </td>
                {props.showInterpretation &&
                    <td>
                        <ul>
                            <li>On-going self-assessment of coping resources</li>
                            <li>Our ability to organize and manage our resources (such as time and energy)</li>
                            <li>Our ability to set limits on things that cause stress</li>
                        </ul>
                    </td>
                }
                    {props.showReference &&
                        <>
                        <td>73.71  </td>
                        <td>14.37</td>
                        <td>44.97</td>
                        <td>{(90 - props.model.stressManagement.score).toFixed(2)}</td>
                        </>
                    }
 
            </tr>
            <tr>
                <th colSpan={props.showReference ? 6 : 3}>Social Self</th>
            </tr>
            <tr>
                <td>
                    Friendship
                </td>
                <td className={props.model.friendship.coding}>
                    {props.model.friendship.score.toFixed(2)}
                </td>
                {props.showInterpretation &&
                    <td>
                        <ul>
                            <li>Social relationships that involve a connection with others individually or in community</li>
                            <li>Having a capacity to trust others</li>
                            <li>Having empathy for others</li>
                            <li>Feeling understood by others</li>
                        </ul>
                    </td>
                }
                    {props.showReference &&
                        <>
                        <td>85.95  </td>
                        <td>11.73</td>
                        <td>62.50</td>
                        <td>{(90 - props.model.friendship.score).toFixed(2)}</td>
                        </>
                    }
 
            </tr>
            <tr>
                <td>
                    Love
                </td>
                <td className={props.model.love.coding}>
                    {props.model.love.score.toFixed(2)}
                </td>
                {props.showInterpretation &&
                    <td>
                        <ul>
                            <li>The ability to be intimate and trusting with another person</li>
                            <li>The ability to express affection towards family members or a significant other</li>
                        </ul>
                    </td>
                }
                    {props.showReference &&
                        <>
                        <td>88.47  </td>
                        <td>12.91</td>
                        <td>62.65</td>
                        <td>{(90 - props.model.love.score).toFixed(2)}</td>
                        </>
                    }
 
            </tr>
            <tr>
                <th colSpan={props.showReference ? 6 : 3}>Essential Self</th>
            </tr>
            <tr>
                <td>
                    Spirituality
                </td>
                <td className={props.model.spirituality.coding}>
                    {props.model.spirituality.score.toFixed(2)}
                </td>
                {props.showInterpretation &&
                    <td>
                        <ul>
                            <li> Personal beliefs and behaviours practiced as part of the recognition that we are more than body and mind</li>
                            <li>Dimensions include:
                                <ul>
                                    <li>belief in a higher power</li>
                                    <li>Hope and optimism</li>
                                    <li>practice of worship, prayer, and/or meditation, either
                                        alone or through organized religious or spiritual practices
                                        with others</li>
                                    <li>Purpose in life</li>
                                    <li>Compassion for others</li>
                                    <li>Moral values</li>
                                    <li>A sense of oneness with the universe</li>
                                </ul>
                            </li>
                        </ul>
                    </td>
                }
                    {props.showReference &&
                        <>
                        <td>76.75  </td>
                        <td>18.08</td>
                        <td>40.59</td>
                        <td>{(90 - props.model.spirituality.score).toFixed(2)}</td>
                        </>
                    }
 
            </tr>
            <tr>
                <td>
                    Gender Identity
                </td>
                <td className={props.model.genderIdentity.coding}>
                    {props.model.genderIdentity.score.toFixed(2)}
                </td>
                {props.showInterpretation &&
                    <td>
                        <ul>
                            <li>Satisfaction with and feeling support in one’s gender</li>
                            <li>The ability to connect with both the masculine and feminine</li>
                        </ul>
                    </td>
                }
                    {props.showReference &&
                        <>
                        <td>81.55  </td>
                        <td>12.12</td>
                        <td>69.43</td>
                        <td>{(90 - props.model.genderIdentity.score).toFixed(2)}</td>
                        </>
                    }
 
            </tr>
            <tr>
                <td>
                    Cultural Identity
                </td>
                <td className={props.model.culturalIdentity.coding}>
                    {(props.model.culturalIdentity.score).toFixed(2)}
                </td>
                {props.showInterpretation &&
                    <td>
                        <ul>
                            <li>Satisfaction with and feeling support in one’s cultural identity</li>
                            <li>The ability to feel comfortable with persons of other cultural</li>
                            backgrounds
                            <li>Having a connection to your own cultural background</li>

                        </ul>
                    </td>
                }
                    {props.showReference &&
                        <>
                        <td>77.40   </td>
                        <td>14.46</td>
                        <td>48.48</td>
                        <td>{(90 - props.model.culturalIdentity.score).toFixed(2)}</td>
                        </>
                    }
 
            </tr>
            <tr>
                <td>
                    Self-Care
                </td>
                <td className={props.model.selfCare.coding}>
                    {props.model.selfCare.score.toFixed(2)}
                </td>
                {props.showInterpretation &&
                    <td>
                        <ul>
                            <li>Taking responsibility for one’s wellness through self-care</li>
                            <li>Safety habits that are preventative in nature</li>
                            <li>Not doing things known to harm us</li>
                        </ul>
                    </td>
                }
                    {props.showReference &&
                        <>
                        <td>84.55  </td>
                        <td>15.60</td>
                        <td>53.35</td>
                        <td>{(90 - props.model.selfCare.score).toFixed(2)}</td>
                        </>
                    }
 
            </tr>
            <tr>
                <th colSpan={props.showReference ? 6 : 3}>Physical Self</th>
            </tr>
            <tr>
                <td>
                    Exercise
                </td>
                <td className={props.model.exercise.coding}>
                    {props.model.exercise.score.toFixed(2)}
                </td>
                {props.showInterpretation &&
                    <td>
                        <ul>
                            <li>Engaging in sufficient physical activity through daily activities and lifestyle habits</li>
                            <li>Stretching, cardiovascular activity, and weight-bearing for flexibility, endurance and strength</li>

                        </ul>
                    </td>
                }
                    {props.showReference &&
                        <>
                        <td>74.82  </td>
                        <td>16.45</td>
                        <td>41.92</td>
                        <td>{(90 - props.model.exercise.score).toFixed(2)}</td>
                        </>
                    }
 
            </tr>
            <tr>
                <td>
                    Nutrition
                </td>
                <td className={props.model.nutrition.coding}>
                    {props.model.nutrition.score.toFixed(2)}
                </td>
                {props.showInterpretation &&
                    <td>
                        <ul>
                            <li>Eating a balanced diet</li>
                            <li>Maintaining a healthy weight for your age and size</li>
                            <li>Not overeating</li>
                        </ul>
                    </td>
                }
                    {props.showReference &&
                        <>
                        <td>70.13  </td>
                        <td>20.62</td>
                        <td>28.89</td>
                        <td>{(90 - props.model.nutrition.score).toFixed(2)}</td>
                        </>
                    }
 
            </tr>
        </table>
    );
}