import { Layout, LoadingIndicator, NavigationBar, Section, TextBlock, Title, useInitializeView } from "@careevolution/mydatahelps-ui";
import { useState } from "react";
import MyDataHelps, { ParticipantInfo } from "@careevolution/mydatahelps-js";

export default function() {
	const [loading, setLoading] = useState(true);
	const [participantInfo, setParticipantInfo] = useState<ParticipantInfo>();
	
	useInitializeView(() => {
		MyDataHelps.getParticipantInfo().then((currentParticipantInfo) => {
			setParticipantInfo(currentParticipantInfo);
			setLoading(false);
		});
	});
	
	if (loading || !participantInfo) {
		return (
			<Layout colorScheme='light'><LoadingIndicator/></Layout>
			);
	}
		
	var allGoals = [
		[participantInfo.customFields.zSG_improveYourStressManagement, "Improve your stress management"],
		[participantInfo.customFields.zSG_improveYourSleep, "Improve your sleep"],
		[participantInfo.customFields.zSG_quitSmoking, "Quit smoking"],
		[participantInfo.customFields.zSG_cutBackOnSubstanceUse, "Cut back on substance use"],
		[participantInfo.customFields.zSG_improveYourPhysicalWellness, "Improve your physical wellness"],
		[participantInfo.customFields.zSG_improveYourLoveWellness, "Improve your love wellness"],
		[participantInfo.customFields.zSG_increaseYourWorkWellness, "Increase your work wellness"],
		[participantInfo.customFields.zSG_increaseYourFriendshipWellness, "Increase your friendship wellness"],
		[participantInfo.customFields.zSG_improveYourNutritionWellness, "Improve your nutrition wellness"],
		[participantInfo.customFields.zSG_improveYourThinking, "Improve your thinking"],
		[participantInfo.customFields.zSG_improveTheExperiencingOfYourEmotions, "Improve the experiencing of your emotions"],
		[participantInfo.customFields.zSG_increaseYourLeisureWellness, "Increase your leisure wellness"],
		[participantInfo.customFields.zSG_increaseYourSenseOfControl, "Increase your sense of control"],
		[participantInfo.customFields.zSG_increaseYourPositiveHumour, "Increase your positive humour"],
		[participantInfo.customFields.zSG_increaseYourSelfWorth, "Increase your self worth"],
		[participantInfo.customFields.zSG_improveYourRealisticBeliefs, "Improve your realistic beliefs"],
		[participantInfo.customFields.zSG_increaseYourSpiritualWellness, "Increase your spiritual wellness"],
		[participantInfo.customFields.zSG_enhanceYourGenderIdentity, "Enhance your gender identity"],
		[participantInfo.customFields.zSG_enhanceYourCulturalIdentity, "Enhance your cultural identity"],
		[participantInfo.customFields.zSG_improveYourSelfCare, "Improve your self care"]
	]
	var goals = allGoals.filter((g) => g[0] === 'true').map((g) => g[1]);

	return (
		<Layout colorScheme="light">
			<NavigationBar showCloseButton={true}></NavigationBar>
				<Title defaultMargin={true}>Suggested Goals</Title>
				<Title defaultMargin={true} order={4}>These are the goals that were originally suggested to you based on your assessment results</Title>
				<Section>
					<TextBlock>
						<ul>
						{goals.map((g) => <li key={g}>{g}</li>)}
						</ul>
						{goals.length === 0 &&
							<>No Suggested Goals Set!</>
						}
					</TextBlock>
				</Section>
		</Layout>
	);
}