import { useEffect, useState } from 'react';
import './Training.css'
import MyDataHelps, { Guid, SurveyTask, SurveyTaskQueryParameters, SurveyTaskStatus } from '@careevolution/mydatahelps-js';
import { Button, Card, CardTitle, Layout, LoadingIndicator, UnstyledButton } from '@careevolution/mydatahelps-ui';
import parseISO from 'date-fns/parseISO';
import { faChevronRight, faCircleCheck, faCircleHalfStroke } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeSvgIcon } from 'react-fontawesome-svg-icon';
import { faCircle } from '@fortawesome/free-regular-svg-icons';
import generateRoute from '../utilities/generateRoute';

class TrainingTask implements SurveyTask {
  id: Guid;
  linkIdentifier: string;
  surveyID: Guid;
  surveyName: string;
  surveyDisplayName?: string | undefined;
  surveyDescription?: string | undefined;
  startDate?: string | undefined;
  endDate?: string | undefined;
  status: SurveyTaskStatus;
  hasSavedProgress: boolean;
  dueDate: string;
  insertedDate: string;
  modifiedDate: string;

  module: string;

  constructor(task: SurveyTask, taskProperties: any) {
    this.id = task.id;
    this.linkIdentifier = task.linkIdentifier;
    this.surveyID = task.surveyID;
    this.surveyName = task.surveyName;
    this.surveyDisplayName = task.surveyDisplayName;
    this.startDate = task.startDate;
    this.endDate = task.endDate;
    this.status = task.status;
    this.hasSavedProgress = task.hasSavedProgress;
    this.dueDate = task.dueDate;
    this.insertedDate = task.insertedDate;
    this.modifiedDate = task.modifiedDate;

    this.surveyDescription = taskProperties.description;
    this.module = taskProperties.module;
  }
}

type TrainingMap = { [key: string]: TrainingTask[] };

export default function () {
  const [loading, setLoading] = useState(true);
  const [tasks, setTasks] = useState<TrainingMap | null>(null);

  useEffect(() => {
    initialize()
    MyDataHelps.on("applicationDidBecomeVisible", initialize);
    return () => {
      MyDataHelps.off("applicationDidBecomeVisible", initialize);
    }
  }, []);

  function initialize() {
    var loadData = function () {
      var allTasks: SurveyTask[] = [];
      var makeRequest = function (pageID: Guid | null) {
        var parameters: SurveyTaskQueryParameters = {};
        if (pageID) {
          parameters.pageID = pageID;
        }

        return MyDataHelps.querySurveyTasks(parameters).then(function (result) {
          allTasks = allTasks.concat(result.surveyTasks);
          var trainingMap: TrainingMap = {};
          if (result.nextPageID) {
            makeRequest(result.nextPageID);
          } else {
            allTasks.forEach(t => {
              try {
                var taskProperties = JSON.parse(t.surveyDescription || '');
                if (taskProperties.training) {
                  var modules = taskProperties.module.split(',').map((s:string) => s.trim());
                  modules.forEach((module: string) => {
                    if (trainingMap[module]) {
                      trainingMap[module].push(new TrainingTask(t, taskProperties));
                    }
                    else {
                      trainingMap[module] = [new TrainingTask(t, taskProperties)];
                    }
                  });
                }
              }
              catch {
                console.log(t);
              }
            });

            setTasks(trainingMap);
            setLoading(false);
          }
        });
      }
      makeRequest(null);
    }
    loadData();
  }

  return (
    <Layout colorScheme='light' className='chsw55-learning'>
      <div className='section-header'>My Learning</div>
      {loading && !tasks &&
        <LoadingIndicator />
      }
      <Button onClick={() => MyDataHelps.openApplication(generateRoute('/GoalHub'))} defaultMargin={true}>Visit your Goal Hub</Button>
      {!!tasks && Object.keys(tasks).sort()?.map((taskGroup) => {
        let trainingTasks = tasks[taskGroup].sort((a, b) => {
          if (!a.insertedDate || !b.insertedDate) { return 0; }
          if (parseISO(a.insertedDate) > parseISO(b.insertedDate)) { return -1; }
          if (parseISO(a.insertedDate) < parseISO(b.insertedDate)) { return 1; }
          return 0;
        });
        return (
          <Card key={taskGroup}>
            <CardTitle title={taskGroup} />
            <div className="mdhui-survey-task-list">
              {trainingTasks.map((task) =>
                <UnstyledButton key={task.id.toString()} className={"mdhui-single-survey-task mdhui-action " + (task.status === 'complete' ? "complete" : "incomplete")} onClick={() => MyDataHelps.startSurvey(task.surveyName!)}>
                  <div className="status-icon">
                    {task.status === 'complete' &&
                        <FontAwesomeSvgIcon icon={faCircleCheck} />
                    }
                    {task.status !== 'complete' && task.hasSavedProgress &&
                      <FontAwesomeSvgIcon icon={faCircleHalfStroke} />
                    }
                    {task.status !== 'complete' && !task.hasSavedProgress &&
                      <FontAwesomeSvgIcon icon={faCircle} />
                    }
                  </div>
                  <div>
                    <div className="survey-name">{task.surveyDisplayName}</div>
                    <div className="survey-description"><>{task.surveyDescription}</></div>
                  </div>
                  <div className="indicator">
                    <FontAwesomeSvgIcon icon={faChevronRight} />
                  </div>
                </UnstyledButton>
              )}
            </div>
          </Card>
        );
      })}
    </Layout>
  );
}