import { useEffect, useState } from 'react';
import MyDataHelps, { Guid, SurveyAnswer, SurveyAnswersQuery } from '@careevolution/mydatahelps-js';
import { Action, Card, Layout, LoadingIndicator, NavigationBar } from '@careevolution/mydatahelps-ui';
import _ from 'underscore';
import parseISO from 'date-fns/parseISO';
import format from 'date-fns/format';
import { faChevronLeft, faMehBlank } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeSvgIcon } from 'react-fontawesome-svg-icon';
import { FiveFWELResults, FiveFWELScore, getScoreColorCoding } from './FFWELReportInterface';
import FFWELSummaryTable from './FFWELSummaryTable';
import FFWELBreakdownTable from './FFWELBreakdownTable';




const keyToSurveyIdentifierMap: {[key: string]: string} = {
  'score': 'TotalWellness',
  'creativeSelfScore': 'CreativeSelf',
  'copingSelfScore': 'CopingSelf',
  'socialSelfScore': 'SocialSelf',
  'essentialSelfScore': 'EssentialSelf',
  'physicalSelfScore': 'PhysicalSelf',
  'thinking': 'CreativeSelfThinking',
  'emotions': 'CreativeSelfEmotions',
  'control': 'CreativeSelfControl',
  'work': 'CreativeSelfWork',
  'positiveHumor': 'CreativeSelfPositiveHumor',
  'leisure': 'CopingSelfLeisure',
  'selfWorth': 'CopingSelfSelfWorth',
  'realisticBeliefs': 'CopingSelfRealisticBeliefs',
  'stressManagement': 'CopingSelfStressManagement',
  'friendship': 'SocialSelfFriendship',
  'love': 'SocialSelfLove',
  'spirituality': 'EssentialSelfSpirituality',
  'genderIdentity': 'EssentialSelfGenderIdentity',
  'culturalIdentity': 'EssentialSelfCulturalIdentity',
  'selfCare': 'EssentialSelfSelfCare',
  'exercise': 'PhysicalSelfExercise',
  'nutrition': 'PhysicalSelfNutrition',
};

export default function () {
  const [loading, setLoading] = useState(true);
  const [results, setResults] = useState<FiveFWELResults[]>([]);
  const [selectedFFWELReport, setSelectedFFWELReport] = useState<FiveFWELResults | null>(null);

  useEffect(() => {
    initialize()
    MyDataHelps.on("applicationDidBecomeVisible", initialize);
    return () => {
      MyDataHelps.off("applicationDidBecomeVisible", initialize);
    }
  }, []);

  useEffect(() => {
      if (results.length === 1) {
        setSelectedFFWELReport(results[0]);
      }
  }, [results]);

  function getAllResults() {
    var results: SurveyAnswer[] = [];

    function getPage(pageID?: Guid) : Promise<SurveyAnswer[]> {
      var params: SurveyAnswersQuery = {
        surveyName: "FFWEL",
        stepIdentifier: [
          "CreativeSelfThinking",
          "CreativeSelfEmotions",
          "CreativeSelfControl",
          "CreativeSelfWork",
          "CreativeSelfPositiveHumor",
          "CreativeSelf",
          "CopingSelfLeisure",
          "CopingSelfStressManagement",
          "CopingSelfSelfWorth",
          "CopingSelfRealisticBeliefs",
          "CopingSelf",
          "SocialSelfFriendship",
          "SocialSelfLove",
          "SocialSelf",
          "EssentialSelfSpirituality",
          "EssentialSelfGenderIdentity",
          "EssentialSelfCulturalIdentity",
          "EssentialSelfSelfCare",
          "EssentialSelf",
          "PhysicalSelfExercise",
          "PhysicalSelfNutrition",
          "PhysicalSelf",
          "TotalWellness"
        ]
      };
      if (pageID) {
        params.pageID = pageID;
      }

      return MyDataHelps.querySurveyAnswers(params).then(function(page) {
        results.push(...page.surveyAnswers);
        if (page.nextPageID !== null) {
          return getPage(page.nextPageID)
        } else {
          return results;
        }
      });
    }

    return getPage();
  }

  function initialize() {
    MyDataHelps.getParticipantInfo().then((participantInfoResult) => {
      return getAllResults().then(function (answers) {
        var parsedResults: FiveFWELResults[] = [];

        function getAnswer(stepIdentifier: string, answers: SurveyAnswer[]) {
          return answers.find((answer) => answer.stepIdentifier === stepIdentifier);
        }

        function getScore(key: string, answers: SurveyAnswer[]): FiveFWELScore {
          var score = parseFloat(getAnswer(keyToSurveyIdentifierMap[key], answers)?.answers[0]!);
          return {
            score,
            coding: getScoreColorCoding(key, score, participantInfoResult.demographics.gender)
          }
        }

        _.chain(answers)
          .groupBy(function (answer) { return answer.surveyResultID as string })
          .forEach(function (result) {
            parsedResults.push({
              timestamp: parseISO(result[0].insertedDate),
              score: getScore('score', result),
              creativeSelfScore: getScore('creativeSelfScore', result),
              copingSelfScore: getScore('copingSelfScore', result),
              socialSelfScore: getScore('socialSelfScore', result),
              essentialSelfScore: getScore('essentialSelfScore', result),
              physicalSelfScore: getScore('physicalSelfScore', result),
              thinking: getScore('thinking', result),
              emotions: getScore('emotions', result),
              control: getScore('control', result),
              work: getScore('work', result),
              positiveHumor: getScore('positiveHumor', result),
              leisure: getScore('leisure', result),
              selfWorth: getScore('selfWorth', result),
              realisticBeliefs: getScore('realisticBeliefs', result),
              stressManagement: getScore('stressManagement', result),
              friendship: getScore('friendship', result),
              love: getScore('love', result),
              spirituality: getScore('spirituality', result),
              genderIdentity: getScore('genderIdentity', result),
              culturalIdentity: getScore('culturalIdentity', result),
              selfCare: getScore('selfCare', result),
              exercise: getScore('exercise', result),
              nutrition: getScore('nutrition', result),
            })
          });

        setResults(parsedResults);
      });
    }).then(function () {
      setLoading(false);
    });
  }

  if (loading) {
    return <Layout colorScheme='light'><LoadingIndicator/></Layout>
  }

  return (
    <Layout colorScheme='light'>
      <NavigationBar title='Your Wellness Scores' variant='compressed' showCloseButton={true}/>
      <div className='fwel-report'>
      <p>
        The Five Factor Wellness Inventory (FFWEL) was developed to help you learn more about your wellness in multiple
        areas. These areas were identified through research as characteristics of people that live long and live well. By
        studying your scores, you can begin to make choices that will help increase your capacity to live long and live well!
      </p>
      <p>
        The FFWEL is scored on a scale of 25-100, where a higher score indicates more wellness in that area. The
        descriptions of each factor are displayed in the interpretation section of the report to help you better understand
        your results.
      </p>

      <table>
        <tbody>
          <tr>
            <td>
              <b>Score Legend</b>
            </td>
            <td className='optimal'>
              Optimal wellness has been
              achieved! Keep up the
              good work.
            </td>
            <td className='warning'>
              Some improvements could
              be made to achieve
              optimal wellness!
            </td>
            <td className='action'>
              It would be a good idea to
              take action to improve your
              wellness in this area.
            </td>
          </tr>
        </tbody>
      </table>

      {selectedFFWELReport == null &&
        <>
        <p>Please select one of your results:</p>
          {results.map(result => {
            return <Card><Action title={format(result.timestamp, 'MM/dd/yyyy')} onClick={()=>setSelectedFFWELReport(result)}></Action></Card>
          })}
        </>
      }
      {selectedFFWELReport != null &&
        <>
          <Action className='no-chevron' onClick={() => setSelectedFFWELReport(null)} indicatorIcon={faMehBlank}><FontAwesomeSvgIcon icon={faChevronLeft} /> Back to Results List</Action>


        <FFWELSummaryTable model={selectedFFWELReport} showInterpretation={true}/>
        <FFWELBreakdownTable model={selectedFFWELReport} showInterpretation={true} />

        <p>If you have any questions or concerns about this report, please reach out to our team to discuss! You can do so by calling <a href="tel:5067384712">(506) 738-4712</a>, emailing Wellness55@canhs.ca, or booking an appointment on the home page of this app.</p>
        </>
      }
      </div>
    </Layout>
  );
}