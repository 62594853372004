import { FiveFWELResults } from "./FFWELReportInterface";
import './FFWELReport.css';

interface FFWELSummaryTableProps {
    model: FiveFWELResults;
    showInterpretation?: boolean;
    showReference?: boolean;
}

export default function (props: FFWELSummaryTableProps) {

    return (
        <table>
            <tbody>
                <tr>
                    <th className='factor' rowSpan={props.showReference ? 2 : 1}>Factor</th>
                    <th className='score' rowSpan={props.showReference ? 2 : 1}>Score</th>
                    {props.showInterpretation &&
                        <th className='interpretation'>Interpretation</th>
                    }
                    {props.showReference &&
                        <>
                            <th colSpan={3}>Reference: Men (N = 528)</th>
                            <th colSpan={3}>Reference: Women (N = 634)</th>
                        </>
                    }
                </tr>
                {props.showReference &&
                    <tr>
                        <th>Mean</th>
                        <th>SD</th>
                        <th>Mean - 2SD</th>
                        <th>Mean</th>
                        <th>SD</th>
                        <th>Mean - 2SD</th>
                </tr>
                }
                <tr>
                    <td>
                        Total Wellness
                    </td>
                    <td className={props.model.score.coding}>
                        {props.model.score.score.toFixed(2)}
                    </td>
                    {props.showInterpretation &&
                        <td>
                            A general, wholistic description of wellness. This score
                            represents how well you are in all areas.
                        </td>
                    }
                    {props.showReference &&
                        <><td>80.05</td><td> 7.93</td> <td>64.19</td> <td>81.02</td><td> 7.33</td><td> 66.36</td></>
                    }
                </tr>
                <tr>
                    <td>
                        Creative Self
                    </td>
                    <td className={props.model.creativeSelfScore.coding}>
                        {props.model.creativeSelfScore.score.toFixed(2)}
                    </td>
                    {props.showInterpretation &&
                        <td>
                            A combination of five components (thinking, emotions, control,
                            work, and positive humour), this score indicates your wellness in
                            areas that make you unique to the world around you.
                        </td>
                    }
                    {props.showReference &&
                        <><td>81.11</td><td>8.76</td><td>63.59</td><td>80.74</td><td>8.35</td><td>64.04</td></>
                    }
                </tr>
                <tr>
                    <td>
                        Coping Self
                    </td>
                    <td className={props.model.copingSelfScore.coding}>
                        {props.model.copingSelfScore.score.toFixed(2)}
                    </td>
                    {props.showInterpretation &&
                        <td>
                            This score represents how well you regulate your responses to
                            life events and overcome any negative effects. It includes realistic
                            beliefs, stress management, self-worth, and leisure.
                        </td>
                    }
                    {props.showReference &&
                        <><td>78.51</td><td>9.79</td><td> 58.93</td><td>78.82</td><td>8.71</td><td> 61.4</td></>
                    }
                </tr>
                <tr>
                    <td>
                        Social Self
                    </td>
                    <td className={props.model.socialSelfScore.coding}>
                        {props.model.socialSelfScore.score.toFixed(2)}
                    </td>
                    {props.showInterpretation &&
                        <td>
                            The score for this factor considers wellness in all aspects of
                            social connection such as friendship and love.
                        </td>
                    }
                    {props.showReference &&
                        <><td>84.89</td><td> 12.61</td><td> 59.67</td><td> 87.69</td><td> 10.83</td><td>66.03</td></>
                    }
                </tr>
                <tr>
                    <td>
                        Essential Self
                    </td>
                    <td className={props.model.essentialSelfScore.coding}>
                        {props.model.essentialSelfScore.score.toFixed(2)}
                    </td>
                    {props.showInterpretation &&
                        <td>
                            Considering essential aspects of the self (spirituality, self-care,
                            gender identity, and cultural identity), this score indicates
                            wellness how you find meaning in the world and a sense of
                            purpose in life.
                        </td>
                    }
                    {props.showReference &&
                        <><td>78.90</td><td>10.90</td><td>57.1</td><td>81.68</td><td>10.74</td><td>60.2</td></>
                    }
                </tr>
                <tr>
                    <td>
                        Physical Self
                    </td>
                    <td className={props.model.physicalSelfScore.coding}>
                        {props.model.physicalSelfScore.score.toFixed(2)}
                    </td>
                    {props.showInterpretation &&
                        <td>
                            This score represents the biological and physiological processes
                            that are important for living long and well which includes nutrition
                            and exercise.
                        </td>
                    }
                    {props.showReference &&
                        <><td>77.11</td><td>13.57</td><td>49.97</td><td>76.05</td><td>12.34</td><td>51.37</td></>
                    }
                </tr>
            </tbody>
        </table>
    );
}