import { Dot, Label, LineChart, Line, ResponsiveContainer, CartesianGrid, Tooltip, XAxis, YAxis } from 'recharts'
import { LoadingIndicator } from '@careevolution/mydatahelps-ui'
import { GoalDiaryResult } from '../GoalHub/GoalHub'
import './CHSGoalDiaryChart.css';

export interface CHSSurveyAnswerChartProps {
	data: GoalDiaryResult[] | null,
	syncId?: string,
	title: string,
	subtitle: string,
	yAxisLabel?: string,
	xAxisLabel?: string,
	xAxisLabelBarChart?: string,
	yAxisRange?: [string|number, string|number],
	xAxisRange?: [string|number, string|number],
	onDataDetected?: Function
}

interface TickProps {
	x: number,
	y: number,
	stroke: any,
	payload: any
}

function DayTick(props: TickProps) {
	const { x, y, payload } = props;
	var value = payload.value;
	return (
		<text x={x} y={y + 15} textAnchor="middle" fontSize="12">{value}</text>
	);
}

const SkippedWeekDot = (props: any) => {
	const {cx, cy, stroke, payload, fill, r, strokeWidth } = props;

	if (payload.skipped) {
		return (
			<svg x={cx - 5} y={cy - 5} fill="red" height="10px" width="10px" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink"
				viewBox="0 0 460.775 460.775" xmlSpace="preserve">
				<path d="M285.08,230.397L456.218,59.27c6.076-6.077,6.076-15.911,0-21.986L423.511,4.565c-2.913-2.911-6.866-4.55-10.992-4.55
						c-4.127,0-8.08,1.639-10.993,4.55l-171.138,171.14L59.25,4.565c-2.913-2.911-6.866-4.55-10.993-4.55
						c-4.126,0-8.08,1.639-10.992,4.55L4.558,37.284c-6.077,6.075-6.077,15.909,0,21.986l171.138,171.128L4.575,401.505
						c-6.074,6.077-6.074,15.911,0,21.986l32.709,32.719c2.911,2.911,6.865,4.55,10.992,4.55c4.127,0,8.08-1.639,10.994-4.55
						l171.117-171.12l171.118,171.12c2.913,2.911,6.866,4.55,10.993,4.55c4.128,0,8.081-1.639,10.992-4.55l32.709-32.719
						c6.074-6.075,6.074-15.909,0-21.986L285.08,230.397z"/>
			</svg>
		);
	}

	return (
        <Dot cx={cx} cy={cy} r={r} stroke={stroke} fill={fill} strokeWidth={strokeWidth}></Dot>
      );
}

export default function (props: CHSSurveyAnswerChartProps) {
	const GraphToolTip = ({ active, payload }: any) => {
		if (active && payload && payload.length) {
			if(payload[0].payload.skipped) {
				return null;
			}

			return (
				<div className="graph-tooltip">
					<div className="graph-date">{payload[0].payload.date}</div>
					<table className="payload-values">
						<tbody>
							{payload.map((p: any) =>
								<tr key={p.dataKey}>
									<td>{parseFloat(p.value).toFixed(2)}</td>
								</tr>
							)}
						</tbody>
					</table>
				</div>
			);
		}

		return null;
	};

	function tickFormatter(args: any) {
		if (args >= 10000) {
			return Math.floor(args / 1000) + 'K';
		}
		return args;
	}

	return (
		<div className="mdhui-device-data-month-chart chs-goal-diary-chart">
			<div className="title">
				{props.title}
			</div>
			<div style={{ clear: "both" }}></div>
			<div className="subtitle">
				{props.subtitle}
			</div>
			<div style={{ clear: "both" }}></div>
			<div className="chart-container" style={{ height: 'auto' }}>
				{(props.data === null || props.data.length === 0) &&
					<div>
						{props.data !== null &&
							<div className="no-data-label">No Data</div>
						}
						{props.data === null &&
							<LoadingIndicator />
						}
						<ResponsiveContainer width={'100%'} height={250}>
							<LineChart width={400} height={400} data={props.data || []} syncId={props.syncId}>
								<CartesianGrid strokeDasharray="3 3" />
								<YAxis type="number" axisLine={false} interval={0} tickLine={false} width={60} scale="linear"
									domain={props.yAxisRange || ['auto', 'auto']}
								>
									{props.yAxisLabel &&
										<Label value={props.yAxisLabel} angle={-90} dx={-20}></Label>
									}
								</YAxis>
								<XAxis id="myXAxis" tick={DayTick} axisLine={false} dataKey="day" tickMargin={0} minTickGap={0} tickLine={false} interval={1} />
							</LineChart>
						</ResponsiveContainer>
					</div>
				}
				{props.data !== null && props.data.length !== 0 &&
					<ResponsiveContainer width={'100%'} height={250}>
						<LineChart width={400} height={400} data={props.data} syncId={props.syncId}>
							<Line connectNulls type="monotone" dataKey={"response"} stroke={"var(--mdhui-color-primary)"} activeDot={<SkippedWeekDot />} dot={<SkippedWeekDot />} />
							<Tooltip content={<GraphToolTip />} />
							<CartesianGrid strokeDasharray="3 3" />
							<YAxis tickFormatter={tickFormatter} axisLine={false} interval={0} tickLine={false} width={60}
								domain={props.yAxisRange || ['auto', 'auto']}
							>
								{props.yAxisLabel &&
									<Label value={props.yAxisLabel} angle={-90} dx={-20}></Label>
								}
							</YAxis>
							<XAxis id="myXAxis" tick={DayTick} axisLine={false} dataKey="date" tickMargin={0} minTickGap={0} tickLine={false} interval={'preserveStartEnd'} height={40}>
								{props.xAxisLabel &&
									<Label value={props.xAxisLabel} dy={15}></Label>
								}
							</XAxis>
						</LineChart>
					</ResponsiveContainer>
				}
			</div>
		</div>
	);
}