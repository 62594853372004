import { useEffect, useState } from 'react';
import './InitialAssessmentReport.css'
import MyDataHelps, { SurveyAnswer, SurveyTask } from '@careevolution/mydatahelps-js';
import { Layout, LoadingIndicator, NavigationBar } from '@careevolution/mydatahelps-ui';
import parseISO from 'date-fns/parseISO';
import subDays from 'date-fns/subDays';
import addDays from 'date-fns/addDays';

export default function () {
  const [loading, setLoading] = useState<boolean>(true);
  const [dhlsScore, setDHLSScore] = useState<string>();
  const [gaqFlag, setGAQFlag] = useState<string>();
  const [srrsScore, setSRRSScore] = useState<string>();
  const [psqiScore, setPSQIScore] = useState<string>();
  const [scrn14Score, setSCRN14Score] = useState<string>();
  const [nvsScore, setNVSScore] = useState<string>();
  const [mohcScore, setMOHCScore] = useState<string>();
  const [gseScore, setGSEScore] = useState<string>();
  const [sfaceScore, setSFACEScore] = useState<string>();
  const [bmi, setBMI] = useState<string>();

  const [whodasScore, setWHODASScore] = useState<string>();
  const [whodasCognitionScore, setWHODASCognitionScore] = useState<string>();
  const [whodasMobilityScore, setWHODASMobilityScore] = useState<string>();
  const [whodasSelfCareScore, setWHODASSelfCareScore] = useState<string>();
  const [whodasGettingAlongScore, setWHODASGettingAlongScore] = useState<string>();
  const [whodasLifeActivitesScore, setWHODASLifeActivitesScore] = useState<string>();
  const [whodasParticipationInSocietyScore, setWHODASParticipationInSocietyScore] = useState<string>();

  useEffect(() => {
    initialize()
    MyDataHelps.on("applicationDidBecomeVisible", initialize);
    return () => {
      MyDataHelps.off("applicationDidBecomeVisible", initialize);
    }
  }, []);

  function initialize() {

    function createWindow(date: Date) {
      return [subDays(date, 1), addDays(date, 1)];
    }
    function findAnswerForTask(answers: SurveyAnswer[], task: SurveyTask) {
      var answer = answers.filter(a => a.taskID === task.id);
      if(answer.length > 0) return answer[0].answers[0];

      return "";
    }
    function findAnswersForTask(answers: SurveyAnswer[], task: SurveyTask) {
      return answers.filter(a => a.taskID === task.id);
    }

    Promise.all([

      MyDataHelps.querySurveyTasks({ surveyName: 'DHLS', sortOrder: 'ascending', status: 'complete', limit: 1 }).then((result) => {
        if (result.surveyTasks.length > 0) {
          var [after, before] = createWindow(parseISO(result.surveyTasks[0].endDate!));
          return MyDataHelps.querySurveyAnswers({ surveyName: 'DHLS', after: after.toISOString(), before: before.toISOString(), stepIdentifier: 'DHLS_score' }).then((answers) => {
            setDHLSScore(findAnswerForTask(answers.surveyAnswers, result.surveyTasks[0]));
          });
        }
      }),

      MyDataHelps.querySurveyTasks({ surveyName: 'GAQ', sortOrder: 'ascending', status: 'complete', limit: 1 }).then((result) => {
        if (result.surveyTasks.length > 0) {
          var [after, before] = createWindow(parseISO(result.surveyTasks[0].endDate!));
          return MyDataHelps.querySurveyAnswers({ surveyName: 'GAQ', after: after.toISOString(), before: before.toISOString(), stepIdentifier: 'ThankYou' }).then((answers) => {
            setGAQFlag(findAnswerForTask(answers.surveyAnswers, result.surveyTasks[0]));
          });
        }
      }),

      MyDataHelps.querySurveyTasks({ surveyName: 'SRRS', sortOrder: 'ascending', status: 'complete', limit: 1 }).then((result) => {
        if (result.surveyTasks.length > 0) {
          var [after, before] = createWindow(parseISO(result.surveyTasks[0].endDate!));
          return MyDataHelps.querySurveyAnswers({ surveyName: 'SRRS', after: after.toISOString(), before: before.toISOString(), stepIdentifier: 'SRRS_score' }).then((answers) => {
            setSRRSScore(findAnswerForTask(answers.surveyAnswers, result.surveyTasks[0]));
          });
        }
      }),

      MyDataHelps.querySurveyTasks({ surveyName: 'PSQI', sortOrder: 'ascending', status: 'complete', limit: 1 }).then((result) => {
        if (result.surveyTasks.length > 0) {
          var [after, before] = createWindow(parseISO(result.surveyTasks[0].endDate!));
          return MyDataHelps.querySurveyAnswers({ surveyName: 'PSQI', after: after.toISOString(), before: before.toISOString(), stepIdentifier: 'PSQI_score' }).then((answers) => {
            setPSQIScore(findAnswerForTask(answers.surveyAnswers, result.surveyTasks[0]));
          });
        }
      }),

      MyDataHelps.querySurveyTasks({ surveyName: 'SCRN14', sortOrder: 'ascending', status: 'complete', limit: 1 }).then((result) => {
        if (result.surveyTasks.length > 0) {
          var [after, before] = createWindow(parseISO(result.surveyTasks[0].endDate!));
          return MyDataHelps.querySurveyAnswers({ surveyName: 'SCRN14', after: after.toISOString(), before: before.toISOString(), stepIdentifier: 'Thank You' }).then((answers) => {
            setSCRN14Score(findAnswerForTask(answers.surveyAnswers, result.surveyTasks[0]));
          });
        }
      }),

      MyDataHelps.querySurveyTasks({ surveyName: 'NVS', sortOrder: 'ascending', status: 'complete', limit: 1 }).then((result) => {
        if (result.surveyTasks.length > 0) {
          var [after, before] = createWindow(parseISO(result.surveyTasks[0].endDate!));
          return MyDataHelps.querySurveyAnswers({ surveyName: 'NVS', after: after.toISOString(), before: before.toISOString(), stepIdentifier: 'NVS_score' }).then((answers) => {
            setNVSScore(findAnswerForTask(answers.surveyAnswers, result.surveyTasks[0]));
          });
        }
      }),

      MyDataHelps.querySurveyTasks({ surveyName: 'MOHC', sortOrder: 'ascending', status: 'complete', limit: 1 }).then((result) => {
        if (result.surveyTasks.length > 0) {
          var [after, before] = createWindow(parseISO(result.surveyTasks[0].endDate!));
          return MyDataHelps.querySurveyAnswers({ surveyName: 'MOHC', after: after.toISOString(), before: before.toISOString(), stepIdentifier: 'MOHC_score' }).then((answers) => {
            setMOHCScore(findAnswerForTask(answers.surveyAnswers, result.surveyTasks[0]));
          });
        }
      }),

      MyDataHelps.querySurveyTasks({ surveyName: 'GSE', sortOrder: 'ascending', status: 'complete', limit: 1 }).then((result) => {
        if (result.surveyTasks.length > 0) {
          var [after, before] = createWindow(parseISO(result.surveyTasks[0].endDate!));
          return MyDataHelps.querySurveyAnswers({ surveyName: 'GSE', after: after.toISOString(), before: before.toISOString(), stepIdentifier: 'GSE_score' }).then((answers) => {
            setGSEScore(findAnswerForTask(answers.surveyAnswers, result.surveyTasks[0]));
          });
        }
      }),

      MyDataHelps.querySurveyTasks({ surveyName: 'SFACE', sortOrder: 'ascending', status: 'complete', limit: 1 }).then((result) => {
        if (result.surveyTasks.length > 0) {
          var [after, before] = createWindow(parseISO(result.surveyTasks[0].endDate!));
          return MyDataHelps.querySurveyAnswers({ surveyName: 'SFACE', after: after.toISOString(), before: before.toISOString(), stepIdentifier: 'SFACE_score' }).then((answers) => {
            setSFACEScore(findAnswerForTask(answers.surveyAnswers, result.surveyTasks[0]));
          });
        }
      }),

      MyDataHelps.querySurveyTasks({ surveyName: 'MHI', sortOrder: 'ascending', status: 'complete', limit: 1 }).then((result) => {
        if (result.surveyTasks.length > 0) {
          var [after, before] = createWindow(parseISO(result.surveyTasks[0].endDate!));
          return MyDataHelps.querySurveyAnswers({ surveyName: 'MHI', after: after.toISOString(), before: before.toISOString(), stepIdentifier: 'bmi_calculation' }).then((answers) => {
            setBMI(findAnswerForTask(answers.surveyAnswers, result.surveyTasks[0]));
          });
        }
      }),

      MyDataHelps.querySurveyTasks({ surveyName: 'WHODAS', sortOrder: 'ascending', status: 'complete', limit: 1 }).then((result) => {
        if (result.surveyTasks.length > 0) {
          var [after, before] = createWindow(parseISO(result.surveyTasks[0].endDate!));
          var stepIdentifiers = ['cognition_score', 'mobility_score', 'selfCare_score', 'gettingAlong_score', 'lifeActivities_score', 'participationInSociety_score', 'WHODAS_score'];
          return MyDataHelps.querySurveyAnswers({
            surveyName: 'WHODAS',
            after: after.toISOString(),
            before: before.toISOString(),
            stepIdentifier: stepIdentifiers
          }).then((answers) => {
            findAnswersForTask(answers.surveyAnswers, result.surveyTasks[0]).forEach((answer) => {
              if (answer.stepIdentifier === "WHODAS_score") { setWHODASScore(answer.answers[0]); }
              if (answer.stepIdentifier === "participationInSociety_score") { setWHODASParticipationInSocietyScore(answer.answers[0]); }
              if (answer.stepIdentifier === "gettingAlong_score") { setWHODASGettingAlongScore(answer.answers[0]); }
              if (answer.stepIdentifier === "selfCare_score") { setWHODASSelfCareScore(answer.answers[0]); }
              if (answer.stepIdentifier === "mobility_score") { setWHODASMobilityScore(answer.answers[0]); }
              if (answer.stepIdentifier === "cognition_score") { setWHODASCognitionScore(answer.answers[0]); }
              if (answer.stepIdentifier === "lifeActivities_score") { setWHODASLifeActivitesScore(answer.answers[0]); }
            });
          });
        }
      })
    ]).then(() => {
      setLoading(false);
    });

  }

  function truncateFloatString(field: string | undefined) {
    if (!field) return "";
    return parseFloat(parseFloat(field).toFixed(2));
  }

  function dhlsInterpretation(score: string | undefined) {
    if (!score) return <></>;

    var numericalScore = parseFloat(score);
    if (numericalScore <= 4) {
      return (<>A score of {score} on the DHLS indicates that you may require additional assistance throughout the program accessing and using our digital sevices <br /> We are happy to help you any way we can!</>);
    } else if (numericalScore > 4 && numericalScore < 10) {
      return (<>A score of {score} on the DHLS indicates you likely have the skills necessary to engage with our digital services. <br /> If you need help with anything we are here to help! </>);
    } else if (numericalScore >= 10) {
      return (<>A score of {score} on the DHLS indicates that you are confident in your abilities to engage with our digital services. <br /> If you need help with anything we are here to help! </>);
    }

    return <></>;
  }

  function gaqInterpretation(score: string | undefined) {
    if (!score) return <></>;

    if (score === 'no flag') {
      return <>Your responses indicate that, in general, it is safe for you to become more physically active!</>;
    }
    else if (score === 'flag') {
      return <>Your responses to this questionnaire indicate that you may have some medical concerns that could limit your ability to become more physically active. Please consult with the wellness team before proceeding with increasing your physical activity.</>;
    }

    return <></>;
  }

  function holmesRaheInterpretation(score: string | undefined) {
    if (!score) return <></>;

    var numericalScore = parseFloat(score);
    if (numericalScore <= 150) {
      return <>A score of {score} on the <i>Holmes-Rahe Life Stress Inventory </i>  indicates a relativley low amount of life change and low susceptibilty to stress-induced health breakdown </>;
    } else if (numericalScore > 150 && numericalScore < 300) {
      return <>A score of {score} on the <i>Holmes-Rahe Life Stress Inventory </i> indicates a moderate amount of life change and implies about a 50% chance of stress-induced health breakdown over the next 2 years</>;
    } else if (numericalScore >= 300) {
      return <>A score of {score} on the <i>Holmes-Rahe Life Stress Inventory </i> indicates a high amount of life change and implies about a 80% chance of stress-induced health breakdown over the next 2 years</>;
    }

    return <></>;
  }

  function psqiInterpretation(score: string | undefined) {
    if (!score) return <></>;

    var numericalScore = parseFloat(score);
    if (numericalScore < 5) {
      return <>A score of {score} on the PSQI indicates a good sleep quality with no major concerns.</>;
    }
    else if (numericalScore >= 5 && numericalScore <= 10) {
      return <>A score of {score} on the PSQI indicates poor sleep quality. You may wish to discuss this with your primary health care provider.</>;
    }
    else if (numericalScore > 10) {
      return <>A score of {score} on the PSQI indicates a very poor sleep quality. We recommend discussing this with your primary health care provider.</>;
    }

    return <></>;
  }

  function scrn14Interpretation(score: string | undefined) {
    if (!score) return <></>;

    var numericalScore = parseFloat(score);
    if (numericalScore > 54) {
      return <>A score of {score} on the SCREEN-14 indicates good nutrition status with no major concerns.</>;
    }
    else if (numericalScore >= 50 && numericalScore <= 54) {
      return <>A score of {score} on the SCREEN-14 indicates that there may be some areas of concern related to your nutritional status. You may wish to discuss this with the wellness team and/or your primary health care provider.</>;
    }
    else if (numericalScore < 50) {
      return <>A score of {score} on the SCREEN-14 indicates that there are some areas of concern related to your nutritional status. We recommend discussing with your primary health care provider.</>;
    }

    return <></>;
  }

  function nvsInterpretation(score: string | undefined) {
    if (!score) return <></>;

    var numericalScore = parseFloat(score);
    if (numericalScore <= 1 ) {
      return <>A score of {score} on the Newest Vital Sign questionnaire indicates a high likelihood of lower health literacy. If you need additional assistance completing questionnaires or accessing resources, please let us know!</>;
    }
    else if (numericalScore >= 2  && numericalScore <= 3) {
      return <>A score of {score} on the Newest Vital Sign questionnaire indicates a possibility of lower health literacy. If you need additional assistance completing questionnaires or accessing resources, please let us know!</>;
    }
    else if (numericalScore > 3) {
      return <>A score of {score} on the Newest Vital Sign questionnaire indicates adequate health literacy. If you need additional assistance completing questionnaires or accessing resources, please let us know!</>;
    }

    return <></>;
  }

  function mohcInterpretation(score: string | undefined) {
    if (!score) return <></>;

    var numericalScore = parseFloat(score);
    if (numericalScore < 6) {
      return <>A score of {score} on the Managing Own Healthcare scale indicates that you have minimal difficulties with accessing and engaging with healthcare services. If you need additional assistance throughout this program, please let us know so that we can help you reach your goals!</>;
    }
    else if (numericalScore >= 6  && numericalScore <= 9) {
      return <>A score of {score} on the Managing Own Healthcare scale indicates that you may struggle with accessing and engaging with healthcare services. If you need additional assistance throughout this program, please let us know so that we can help you reach your goals!</>;
    }
    else if (numericalScore > 9) {
      return <>A score of {score} on the Managing Own Healthcare scale indicates that you tend to struggle with accessing and engaging with healthcare services. If you need additional assistance throughout this program, please let us know so that we can help you reach your goals!</>;
    }

    return <></>;
  }

  function gseInterpretation(score: string | undefined) {
    if (!score) return <></>;

    var numericalScore = parseFloat(score);
    if (numericalScore < 20) {
      return <>A score of {score} on the General Self-Efficacy scale indicates that you tend to struggle with your motivation and ability to complete tasks. If you need additional assistance throughout this program, please let us know so that we can help you reach your goals!</>;
    }
    else if (numericalScore >= 20  && numericalScore <= 30) {
      return <>A score of {score} on the General Self-Efficacy scale indicates that you may struggle with your motivation and ability to complete tasks. If you need additional assistance throughout this program, please let us know so that we can help you reach your goals!</>;
    }
    else if (numericalScore > 30) {
      return <>A score of {score} on the General Self-Efficacy scale indicates that you have minimal difficulties with your motivation and ability to complete tasks. However, if you do need additional assistance throughout this program, please let us know so that we can help you reach your goals!</>;
    }

    return <></>;
  }

  function sfaceInterpretation(score: string | undefined) {
    if (!score) return <></>;

    var numericalScore = parseFloat(score);
    if (numericalScore < 10) {
      return <>A score of {score} on the Altarum Consumer Engagement scale indicates that you tend to struggle with navigating resources and commitment to programming. If you need additional assistance throughout this program, please let us know so that we can help you reach your goals!</>;
    }
    else if (numericalScore >= 10  && numericalScore <= 24) {
      return <>A score of {score} on the  Altarum Consumer Engagement  scale indicates that you may struggle with navigating resources and commitment to programming. If you need additional assistance throughout this program, please let us know so that we can help you reach your goals!</>;
    }
    else if (numericalScore > 24) {
      return <>A score of {score} on the  Altarum Consumer Engagement scale indicates that you have minimal difficulties with navigating resources and commitment to programming. However, if you do need additional assistance throughout this program, please let us know so that we can help you reach your goals!</>;
    }

    return <></>;
  }


  function bmiInterpretation(score: string | undefined) {
    if (!score) return <></>;

    var numericalScore = parseFloat(score);
    if (numericalScore < 18.5) {
      return <>A BMI under 18.5 indicates that you may be underweight. Consider your current nutrition status and ways in which you can reach a healthier weight for your height.</>;
    }
    else if (numericalScore >= 18.5 && numericalScore < 25) {
      return <>A BMI between 18.5 and 24.99 indicates that you are at a normal weight for your height!</>;
    }
    else if (numericalScore >= 25) {
      return <>A BMI over 25 indicates that you may be overweight or obese for your height. Since this is a risk factor for many health conditions, consider taking healthy steps to reduce your weight.</>;
    }

  }
  if (loading) {
    return <Layout colorScheme='light'><LoadingIndicator/></Layout>
  }

  return (
    <Layout colorScheme='light'>
      <NavigationBar title='Initial Assessment Report' variant='compressed' showCloseButton={true}/>
      <div className='initial-assessment-report'>
      <p>
        Thank you for taking the time to complete your initial assessment. Based on your responses we have
        computed your scores for the various assessment and provided an interpretation of your results.
      </p>
      <table>
        <tr>
          <th className='assessment'>Assessment</th>
          <th className='score'>Score</th>
          <th className='interpretation'>Interpretation</th>
        </tr>
        <tr>
          <td>
            Digital Health Literacy Scale
          </td>
          <td>
            {truncateFloatString(dhlsScore)}
          </td>
          <td>
            {dhlsInterpretation(dhlsScore)}
          </td>
        </tr>

        <tr>
          <td>
            Get Active Questionnaire
          </td>
          <td>
            {gaqFlag || ""}
          </td>
          <td>
            {gaqInterpretation(gaqFlag)}
          </td>
        </tr>


        <tr>
          <td>
            Life Events Checklist
          </td>
          <td>
            {truncateFloatString(srrsScore)}
          </td>
          <td>
            {holmesRaheInterpretation(srrsScore)}
          </td>
        </tr>


        <tr>
          <td>
            About your Sleep Quality
          </td>
          <td>
            {truncateFloatString(psqiScore)}
          </td>
          <td>
            {psqiInterpretation(psqiScore)}
          </td>
        </tr>

        <tr>
          <td>
            Older Adult Nutrition Screening
          </td>
          <td>
            {truncateFloatString(scrn14Score)}
          </td>
          <td>
            {scrn14Interpretation(scrn14Score)}
          </td>
        </tr>

        <tr>
          <td>
            Health Literacy Skills
          </td>
          <td>
            {truncateFloatString(nvsScore)}
          </td>
          <td>
            {nvsInterpretation(nvsScore)}
          </td>
        </tr>

        <tr>
          <td>
            Managing Own Health Care
          </td>
          <td>
            {truncateFloatString(mohcScore)}
          </td>
          <td>
            {mohcInterpretation(mohcScore)}
          </td>
        </tr>

        <tr>
          <td>
            General Self Efficacy Scale
          </td>
          <td>
            {truncateFloatString(gseScore)}
          </td>
          <td>
            {gseInterpretation(gseScore)}
          </td>
        </tr>

        <tr>
          <td>
            Health Engagement
          </td>
          <td>
            {truncateFloatString(sfaceScore)}
          </td>
          <td>
            {sfaceInterpretation(sfaceScore)}
          </td>
        </tr>

        <tr>
          <td>
            BMI Body Mass Index
          </td>
          <td>
            {truncateFloatString(bmi)}
          </td>
          <td>
            {bmiInterpretation(bmi)}
          </td>
        </tr>

      </table>
      <h3>Functional Ability Score</h3>
      <p>When looking at the following scores, it is important to remember that disability is part of being human
        and refers to difficulties experienced in functioning. The World Health Organization Disability Assessment
        Schedule 2.0 (WHODAS 2.0) assesses level of disability overall and across 6 domains of life. Scores range
        from 0 to 100 where 0 indicates no disability and 100 indicates full disability. The higher your score, the
        more difficulty you are likely experiencing in that area of your life. (WHO, 2012)</p>
      <table>
        <tr>
          <th>Domain</th>
          <th>Measurements</th>
          <th>Score</th>
        </tr>
        <tr>
          <td>
            Overall
          </td>
          <td>
            Overall disability score
          </td>
          <td>
            {truncateFloatString(whodasScore)}
          </td>
        </tr>


        <tr>
          <td>
            Thinking
          </td>
          <td>
            Concentrating, remembering, problem  solving, learning, and communicating
          </td>
          <td>
            {truncateFloatString(whodasCognitionScore)}
          </td>
        </tr>


        <tr>
          <td>
            Mobility
          </td>
          <td>
            Standing, moving around inside the  home, getting out of the home, and  walking a long distance
          </td>
          <td>
            {truncateFloatString(whodasMobilityScore)}
          </td>
        </tr>


        <tr>
          <td>
            Self-Care
          </td>
          <td>
            Bathing, dressing, eating, and staying  alone
          </td>
          <td>
              {truncateFloatString(whodasSelfCareScore)}
          </td>
        </tr>


        <tr>
          <td>
            Getting Along
          </td>
          <td>
            Getting along with other people and  difficulties with this due to a health  condition
          </td>
          <td>
              {truncateFloatString(whodasGettingAlongScore)}
          </td>
        </tr>

        <tr>
          <td>
            Life Activities
          </td>
          <td>
            Household, work and/or school  activities
          </td>
          <td>
              {truncateFloatString(whodasLifeActivitesScore)}
          </td>
        </tr>

        <tr>
          <td>
            Participation in Society
          </td>
          <td>
            How other people and the world  around you make it difficult for you to  take part in society
          </td>
          <td>
              {truncateFloatString(whodasParticipationInSocietyScore)}
          </td>
        </tr>

      </table>
      <p>Please note a detailed breakdown of your Five Factor Wellness Inventory score can be found separately under <i>Wellness Scores</i> on the previous page.</p>
      <p>If you have any questions or concerns about this report, please reach out to our team to discuss! You can do so by calling <a href="tel:5067384712">(506) 738-4712</a>, emailing Wellness55@canhs.ca, or booking an appointment on the home page of this app.</p>
      </div>
    </Layout>
  );
}