const keyToCutOffMap: {[key: string]: {[gender: string]: number}} = {
  'score': {'M': 64.19, 'F': 66.36},
  'creativeSelfScore': {'M': 63.59, 'F': 64.04},
  'copingSelfScore': {'M': 58.93, 'F': 61.4},
  'socialSelfScore': {'M': 59.67, 'F': 66.03},
  'essentialSelfScore': {'M': 57.1, 'F': 60.2},
  'physicalSelfScore': {'M': 49.97, 'F': 51.37},
  'thinking': {'M': 56.42, 'F': 56.42},
  'emotions': {'M': 55.93, 'F': 55.93},
  'control': {'M': 58.11, 'F': 58.11},
  'work': {'M': 48.28, 'F': 48.28},
  'positiveHumor': {'M': 56.03, 'F': 56.03},
  'leisure': {'M': 51.32, 'F': 51.32},
  'selfWorth': {'M': 50.26, 'F': 50.26},
  'realisticBeliefs': {'M': 30.70, 'F': 30.70},
  'stressManagement': {'M': 44.97, 'F': 44.97},
  'friendship': {'M': 62.50, 'F': 62.50},
  'love': {'M': 62.65, 'F': 62.65},
  'spirituality': {'M': 40.59, 'F': 40.59},
  'genderIdentity': {'M': 69.43, 'F': 69.43},
  'culturalIdentity': {'M': 48.48, 'F': 48.48},
  'selfCare': {'M': 53.35, 'F': 53.35},
  'exercise': {'M': 41.92, 'F': 41.92},
  'nutrition': {'M': 28.89, 'F': 28.89},
};

export function getScoreColorCoding(key: string, score: number, gender: string) {
    var cutoff = keyToCutOffMap[key][gender];

    if (score > 90) {
        return 'optimal';
    } else if (score < cutoff) {
        return 'action';
    }

    return 'warning';
}

export interface FiveFWELScore {
  score: number;
  coding: string;
}

export interface FiveFWELResults {
  timestamp: Date;
  score: FiveFWELScore;
  creativeSelfScore: FiveFWELScore;
  copingSelfScore: FiveFWELScore;
  socialSelfScore: FiveFWELScore;
  essentialSelfScore: FiveFWELScore;
  physicalSelfScore: FiveFWELScore;
  thinking: FiveFWELScore;
  emotions: FiveFWELScore;
  control: FiveFWELScore;
  work: FiveFWELScore;
  positiveHumor: FiveFWELScore;
  leisure: FiveFWELScore;
  selfWorth: FiveFWELScore;
  realisticBeliefs: FiveFWELScore;
  stressManagement: FiveFWELScore;
  friendship: FiveFWELScore;
  love: FiveFWELScore;
  spirituality: FiveFWELScore;
  genderIdentity: FiveFWELScore;
  culturalIdentity: FiveFWELScore;
  selfCare: FiveFWELScore;
  exercise: FiveFWELScore;
  nutrition: FiveFWELScore;
}