import { MouseEventHandler } from "react";
import './ReportCard.css';
import { LoadingIndicator } from "@careevolution/mydatahelps-ui";

interface ReportCardProps {
    label: string;
    score: string;
    lastTaken?: Date;
    interpretation: string;
    linkText?: string;
    onClick?: MouseEventHandler;
    loading?: boolean;
}

export default function (props: ReportCardProps) {
    if (props.loading) {
        return (
            <div className='report-card'>
                <LoadingIndicator />
            </div>
        );
    }

    return (
        <div className={`report-card ${!!props.onClick ? 'clickable' : ''}`} onClick={props.onClick}>
            {props.lastTaken &&
            <>
            <div className="report-card-title">{props.label}</div>
            <div className="report-card-score">Score: {props.score}</div>
            <div>Date Taken: {props.lastTaken?.toLocaleDateString()}</div>
            <div className="report-card-interpretation" style={{marginBottom: 16}}>{props.interpretation}</div>
            {props.linkText &&
                <a href="#">Click here for more details about your FFWEL Results!</a>
            }
            </>
            }
            {!props.lastTaken &&
                <div>Not Taken</div>
            }

        </div>
    );
}