import { SurveyAnswer } from "@careevolution/mydatahelps-js";
import queryAllSurveyAnswers from "../utilities/queryAllSurveyAnswers";
import parseISO from "date-fns/parseISO";
import { useState } from "react";
import { StringDictionary, parseSMARTResponse } from "./GoalHub";
import { Card, Layout, LoadingIndicator, NavigationBar, Title, useInitializeView } from "@careevolution/mydatahelps-ui";
import SMARTResults from "./SMARTResults";

import './ReviewSMARTResults.css';

interface SMARTAnswer {
	trainingModule: string;
	responseDate: Date;
	answers: StringDictionary
}

export default function(){
	const [smartAnswers, setSmartAnswers] = useState<SMARTAnswer[] | null>(null);
	const [selectedIndex, setSelectedIndex] = useState(0);

	function getAllSMARTResponses() {
		queryAllSurveyAnswers({
			stepIdentifier: "SMART"
		}).then((answers) => {
			const parsedSurveys : {[key: string] : SurveyAnswer[]} = {};
			answers.forEach((answer) => {
				const surveyID = answer.surveyResultID.toString();
				if(!parsedSurveys[surveyID]){
					parsedSurveys[surveyID] = [];
				}
				parsedSurveys[surveyID].push(answer);
			});

			var responses = Object.values(parsedSurveys).sort((a,b) => parseISO(a[0].date).valueOf() - parseISO(b[0].date).valueOf()).map( (responseSet) => {
				return {
					responseDate: parseISO(responseSet[0].date),
					trainingModule: responseSet[0].surveyName,
					answers: parseSMARTResponse(responseSet)
				}
			});

			setSmartAnswers(responses);
		})
	}

	useInitializeView(getAllSMARTResponses);

	if (!smartAnswers) {
		return (
			<Layout colorScheme='light'><LoadingIndicator/></Layout>
			)
	}

	const selectedResponse = smartAnswers[selectedIndex];

	return (
		<Layout className="review-smart-results" colorScheme="light">
			<NavigationBar showCloseButton={true}></NavigationBar>
			<Title defaultMargin={true}>Previous SMART Goals</Title>
			<Card>
				<Title defaultMargin={true} order={2}>
					<label>
						Select a previous Goal:
						<select value={selectedIndex} onChange={e=> setSelectedIndex(parseInt(e.target.value))}>
							{smartAnswers.map( (a,i) => 
								<option value={i.toString()}>{a.trainingModule} {a.responseDate.toLocaleDateString()}</option>
							)}
						</select>
					</label>
				</Title>
				<SMARTResults smartAnswers={selectedResponse.answers}></SMARTResults>
			</Card>
		</Layout>
	);
}