import { Button, Card, CardTitle, Layout, LoadingIndicator, NavigationBar, TextBlock, useInitializeView } from "@careevolution/mydatahelps-ui";
import { useState } from "react";
import MyDataHelps, { SurveyAnswer, SurveyTasksPage } from "@careevolution/mydatahelps-js";
import './GoalHub.css';
import CHSGoalDiaryChart from "../CHSGoalDiaryChart/CHSGoalDiaryChart";
import parseISO from "date-fns/parseISO";
import generateRoute from "../utilities/generateRoute";
import SMARTResults from "./SMARTResults";
import queryAllSurveyAnswers from "../utilities/queryAllSurveyAnswers";

export type StringDictionary = {[key:string] : string};
export type SMARTAnswerDictionary = {[key:string] : StringDictionary};

const goalMapping : StringDictionary = {
	"thinking": "Improve your thinking",
	"experiencingEmotions": "Improve the experiencing of your emotions",
	"senseOfControl": "Increase your sense of control",
	"workWellness": "Increase your work wellness",
	"positiveHumor": "Increase your positive humour",
	"leisureWellness": "Increase your leisure wellness",
	"stressManagement": "Improve your stress management",
	"selfWorth": "Increase your self-worth",
	"realisticBeliefs": "Improve your realistic beliefs",
	"friendshipWellness": "Increase your friendship wellness",
	"loveWellness": "Increase your love wellness",
	"spiritualWellness": "Increase your spiritual wellness",
	"genderIdentity": "Enhance your gender identity",
	"culturalIdentity": "Enhance your cultural identity",
	"selfCare": "Improve your self-care",
	"physicalWellness": "Improve your physical wellness",
	"nutritionWellness": "Improve your nutrition wellness",
	"sleep": "Improve your sleep",
	"quitSmoking": "Quit smoking",
	"substanceUse": "Cut back on substance use",
	"other": "Other"
};

const goalToTMMapping : StringDictionary = {
	"thinking": "Improving Your Thinking",
	"experiencingEmotions": "Improving the Experiencing of Your Emotions",
	"senseOfControl": "Increasing Your Sense of Control",
	"workWellness": "Working Well",
	"positiveHumor": "Increasing Your Positive Humour",
	"leisureWellness": "Increasing Your Leisure Wellness",
	"stressManagement": "Improving Your Stress Management",
	"selfWorth": "Increasing Your Self-Worth",
	"realisticBeliefs": "Improving Your Realistic Beliefs",
	"friendshipWellness": "Increasing Your Friendship Wellness",
	"loveWellness": "Increasing Your Love Wellness",
	"spiritualWellness": "Spiritual Wellness",
	"genderIdentity": "Gender Identity",
	"culturalIdentity": "Cultural Identity",
	"selfCare": "Taking Care of You",
	"physicalWellness": "Improving Your Physical Wellness",
	"nutritionWellness": "Improving Your Eating Habits",
	"sleep": "Improving Your Sleep",
	"quitSmoking": "Smoking Cessation",
	"substanceUse": "The Impact of Substance Use"
};

const TMtoGoalMapping : StringDictionary = {
	"Improving Your Thinking": "Thinking",
	"Improving the Experiencing of Your Emotions": "Experiencing Emotions",
	"Increasing Your Sense of Control": "Sense of Control",
	"Working Well": "Work Wellness",
	"Increasing Your Positive Humour": "Positive Humour",
	"Increasing Your Leisure Wellness": "Leisure Wellness",
	"Improving Your Stress Management": "Stress Management",
	"Increasing Your Self-Worth": "Self-Worth",
	"Improving Your Realistic Beliefs": "Realistic Beliefs",
	"Increasing Your Friendship Wellness": "Friendship Wellness",
	"Increasing Your Love Wellness": "Love Wellness",
	"Spiritual Wellness": "Spiritual Wellness",
	"Gender Identity": "Gender Identity",
	"Cultural Identity": "Cultural Identity",
	"Taking Care of You": "Self-Care",
	"Improving Your Physical Wellness": "Physical Wellness",
	"Improving Your Eating Habits": "Nutrition Wellness",
	"Improving Your Sleep": "Sleep",
	"Smoking Cessation": "Quit Smoking",
	"The Impact of Substance Use": "Cut back on Substance Use"
};

interface Goal {
	title: string;
	trainingModule: string;
	goalIndex: number;
	startDate: Date;
	endDate: Date;
}

interface GoalSet {
	goals: Goal[],
	startDate: string,
	endDate: string,
}

export interface GoalDiaryResult {
	response: string | null;
	date: string;
	dateValue: Date;
	skipped: boolean;
}

export function parseSMARTResponse(answers: SurveyAnswer[]) {
	const smartAnswer: StringDictionary = {};
	answers.forEach((answer) => smartAnswer[answer.resultIdentifier] = answer.answers[0]);
	return smartAnswer;
}

export default function() {
	const [loading, setLoading] = useState(true);
	const [goals, setGoals] = useState<Goal[]>([]);
	const [activeGoal, setActiveGoal] = useState<Goal | null>(null);
	const [smartAnswers, setSmartAnswers] = useState<(StringDictionary | null)[]>([]);
	const [goalDiary, setGoalDiary] = useState<GoalDiaryResult[][]>([]);
	const [errorState, setErrorState] = useState<string|null>(null);

	useInitializeView(() => {
		MyDataHelps.querySurveyTasks({
			//status: 'complete',
			surveyName: 'goalSettingSurvey',
			limit: 1,
			sortOrder: "descending"
		}).then((lastGoalTask) => {
			if(lastGoalTask.surveyTasks.length === 0) {
				setErrorState("Once you've set some goals, you'll be able to access the Goal Hub! Make sure you've completed your tasks and initial learning modules to receive your Goal Setting Survey.");
				setLoading(false);
			}
			else {
				if (lastGoalTask.surveyTasks[0].status === 'complete') {
					queryAllSurveyAnswers({
						surveyName: "goalSettingSurvey",
						stepIdentifier: "GOALS_01",
					}).then((surveyAnswers) => {
						var goalSets : GoalSet[] = surveyAnswers.reverse().map((answer, index) => {
							var endDate: string = '';
							if(index === surveyAnswers.length - 1) {
								endDate = new Date().toISOString();
							}else{
								endDate = surveyAnswers[index + 1].date;
							}
							const goalSet: GoalSet = {
								startDate: answer.date,
								endDate: endDate,
								goals: answer.answers.map((a, index) => {
									return {
										title: goalMapping[a],
										trainingModule: goalToTMMapping[a],
										goalIndex: index,
										startDate: parseISO(answer.date),
										endDate: parseISO(endDate)
									};
								})
							};
							return goalSet;
						});
						Promise.all(
							goalSets.map(goalSet => getGoalDiaries(goalSet.startDate, goalSet.endDate))).then(diaries => {
							console.log(diaries);
							var currentGoalSet = Object.assign({}, goalSets[goalSets.length - 1]);
							var resultingGoalDiary: GoalDiaryResult[][] = [
								[],[],[]
							];
							currentGoalSet.goals.forEach(currentGoal => {
								goalSets.forEach((goalSet, index) => {
									var sameGoalIndex = goalSet.goals.findIndex( g => g.title === currentGoal.title );
									if(sameGoalIndex >= 0) {
										if(goalSet.goals[sameGoalIndex].startDate < currentGoal.startDate ) {
											currentGoal.startDate = goalSet.goals[sameGoalIndex].startDate; 
										}
										if(goalSet.goals[sameGoalIndex].endDate > currentGoal.endDate ) {
											currentGoal.endDate = goalSet.goals[sameGoalIndex].endDate; 
										}
										var oldDiaries = diaries[index][sameGoalIndex]
										resultingGoalDiary[currentGoal.goalIndex].push(...oldDiaries);
									}
								});
							});
							resultingGoalDiary = resultingGoalDiary.filter( r => r.length > 0);
							console.log(resultingGoalDiary);
							Promise.all([
								getSkippedGoalDiaries().then(skippedWeeks => {
									resultingGoalDiary.forEach((goalDiary, index) => addSkippedWeeks(skippedWeeks, goalDiary, currentGoalSet.goals[index].startDate, currentGoalSet.goals[index].endDate));
								}),
								Promise.all(currentGoalSet.goals.map((g) => getSMARTGoalsForModule(g))).then((smartAnswers) => {
									setSmartAnswers(smartAnswers);
								})
							]).then(() => {
								setGoals(currentGoalSet.goals);
								setGoalDiary(resultingGoalDiary);
								setLoading(false);
							})
						});
					});
				}
				else {
					setErrorState("Once you've set some goals, you'll be able to access the Goal Hub! Your Goal Setting Survey is available in your task list.");
					setLoading(false);
				}
			}
		});
	});

	function getSMARTGoalsForModule(goal: Goal) {
		return queryAllSurveyAnswers({
			surveyName: goal.trainingModule,
			stepIdentifier: "SMART",
			limit: 5,
		}).then((surveyAnswers) => {
			if (surveyAnswers.length > 0) {
				return parseSMARTResponse(surveyAnswers);
			}

			return null;
		});
	}

	function getGoalDiaries(goalStartDate: string | undefined, goalEndDate: string | undefined) {
		return queryAllSurveyAnswers({
			surveyName: "goalDiary",
			stepIdentifier: ["GOAL1", "GOAL2", "GOAL3"],
			after: goalStartDate,
			before: goalEndDate,
		}).then(surveyAnswers => {
			var parsedGoalDiary: GoalDiaryResult[][] = [
				[],
				[],
				[]
			];
			surveyAnswers.forEach((answer) => {
				var index = -1;
				if (answer.stepIdentifier === "GOAL1") {
					index = 0;
				}
				else if (answer.stepIdentifier === "GOAL2") {
					index = 1;
				}
				else if (answer.stepIdentifier === "GOAL3") {
					index = 2;
				}
				var responseDate = parseISO(answer.date);
				parsedGoalDiary[index].push({
					response: answer.answers[0],
					date: responseDate.toLocaleDateString(),
					dateValue: responseDate,
					skipped: false
				});
			});

			return parsedGoalDiary;
		});
	}

	function getSkippedGoalDiaries() {
		return MyDataHelps.querySurveyTasks({
			status: "closed",
			surveyName: "goalDiary"
		});/*.then((_results) => {
			var x : SurveyTasksPage = {
				surveyTasks: [
					{
						id: '0',
						linkIdentifier: "",
						surveyID: '0',
						surveyName: "",
						status: "closed",
						hasSavedProgress: false,
						dueDate: "",
						insertedDate: '2024-03-28T17:20:14.545Z',
						modifiedDate: ""
					},
					{
						id: '1',
						linkIdentifier: "",
						surveyID: '1',
						surveyName: "",
						status: "closed",
						hasSavedProgress: false,
						dueDate: "",
						insertedDate: '2024-04-15T17:20:14.545Z',
						modifiedDate: ""
					},
				]
			}
			return x;
		})*/
	}

	function addSkippedWeeks(skippedGoalDiaries: SurveyTasksPage, parsedGoalDiary: GoalDiaryResult[], startDate: Date, endDate: Date) {
		// Set data for skipped weeks.
		skippedGoalDiaries.surveyTasks.forEach((skippedTask) => {
				const missedDate = parseISO(skippedTask.insertedDate);
				if(startDate < missedDate && missedDate < endDate){
					parsedGoalDiary.push({
						response: null,
						date: missedDate.toLocaleDateString(),
						dateValue: missedDate,
						skipped: true
					});
				}
			});

		// Get the data in chronological order so that the skipped weeks are in the right place.
		parsedGoalDiary.sort((a, b) => a.dateValue.valueOf() - b.dateValue.valueOf());

		// Update the data for skipped weeks to have the same value as the week before.
		// This is needed so that Recharts can have a "cy" value for where the dot is 
		// graphed so that we can replace the dot with an X
		parsedGoalDiary.forEach((result, index) => {
			if (result.response == null) {
				if (index > 0) {
					result.response = parsedGoalDiary[index - 1].response;
				} else {
					result.response = "0";
				}
			}
		});
	}

	if (loading) {
		return (
			<Layout colorScheme='light'><LoadingIndicator/></Layout>
		);
	}

	if (errorState != null) {
		return (
			<Layout colorScheme='light'>
				<Card>
					<CardTitle title="Oops! Nothing to see here... yet!"/>
					<TextBlock>
						{errorState}
					</TextBlock>
				</Card>
			</Layout>
		);
	}

	return(
		<Layout className="chs-goal-hub" colorScheme="light">
			<NavigationBar showBackButton={true}></NavigationBar>
			<div className='section-header'>My Goals</div>
			<Card style={{ textAlign: 'center' }}>
				{goals.length === 0 &&
					<div>It looks like you haven't set a SMART goal yet! To do so, please review the learning module on this topic</div>
				}
				<TextBlock >
					Please select one of your goals from the list below to see your progress
				</TextBlock>
				{goals.map( (goal) => {
					return <Button className={activeGoal?.title === goal.title ? "chs-pushed" : ""} key={goal.title} defaultMargin={true} fullWidth={true} onClick={() => setActiveGoal(goal)}>{goal.title}</Button>;
				})}
			</Card>
			{!!activeGoal &&
				<Card>
					<SMARTResults header={TMtoGoalMapping[activeGoal.trainingModule]} smartAnswers={smartAnswers ? smartAnswers[activeGoal.goalIndex] : null} />
					<a href="#"
						onClick={(event) => { event.preventDefault(); MyDataHelps.openApplication(generateRoute('/ReviewSMARTResults'), { modal: true }); } }>
							View Previous SMART Goals
					</a>
					<CHSGoalDiaryChart title="Goal Progress" subtitle="This data is from your weekly goal check in. Remember a 0 means your goal has not been met at all, and a 10 means you have reached your goal. An X on your progress chart indicates that you did not complete your weekly goal check-in on this date." data={goalDiary[activeGoal.goalIndex]} yAxisRange={[0,10]} />
				</Card>
			}
			<a href="#"
				onClick={(event) => { event?.preventDefault(); MyDataHelps.openApplication(generateRoute('/SuggestedGoals'), { modal: true }); }}>
					View Suggested Goals
			</a>
			<TextBlock>
				<p style={{textAlign: 'center'}}>
				Think it is time to update your goals?<br/>
				Contact us at <a href="mailto:wellness55@canhs.ca">wellness55@canhs.ca</a> or <a href="tel:5067384712">(506) 738-4712</a>.
				</p>
			</TextBlock>
		</Layout>
	);
}