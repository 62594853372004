import MyDataHelps from "@careevolution/mydatahelps-js";
import { DailyDataQueryResult, LoadingIndicator, queryDailyData } from "@careevolution/mydatahelps-ui";
import add from "date-fns/add";
import { useEffect, useState } from "react";
import './WearableAverage.css';

interface WearableAverageProps {
    type: string;
    month: number;
    year: number;
    label: string;
    formatter: (average: string) => string;
    valueConverter?: Function;
}

export default function (props: WearableAverageProps) {
    const [dailyData, setDailyData] = useState<DailyDataQueryResult | null>(null);
    const [loading, setLoading] = useState(false);
    
    var monthStart = new Date(props.year, props.month, 1, 0, 0, 0, 0);
    var monthEnd = add(monthStart, { months: 1 });
    
    function initialize() {
        setLoading(true);
        var loadData = function () {
            queryDailyData(props.type, monthStart, monthEnd).then(function (data) {
                setDailyData(data);
                setLoading(false);
            })
        }
        
        loadData();
    }

    function calculateAverage() {
        var values = Object.keys(dailyData || {}).map((d) => dailyData![d]);
        values = values.filter(v => !!v).map(v => props.valueConverter ? props.valueConverter(v) : v);
        var calculated = values.length === 0 ? 0 : values.reduce((a, b) => a + b, 0) / values.length;
        return calculated.toLocaleString('en-US', {
            minimumFractionDigits: 0,
            maximumFractionDigits: 1
        });
    }

    useEffect(() => {
        initialize();
        MyDataHelps.on("applicationDidBecomeVisible", initialize);
        MyDataHelps.on("externalAccountSyncComplete", initialize);
        
        return () => {
            MyDataHelps.off("applicationDidBecomeVisible", initialize);
            MyDataHelps.off("externalAccountSyncComplete", initialize);
        }
    }, [props.month, props.year]);

    var average = props.formatter(calculateAverage());
    return (
          <div className='wearable-average'>
            {loading && <LoadingIndicator/>}
            {!loading &&
                <div className="wearable-average-label">{props.label}</div>
            }
            {!loading &&
                <div className="wearable-average-value">{average}</div>
            }
          </div>
    );
}