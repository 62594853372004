import MyDataHelps, { ParticipantInfo } from "@careevolution/mydatahelps-js";
import ReportCard from "./ReportCard";
import { useEffect, useState } from "react";
import parseISO from "date-fns/parseISO";
import { getScoreColorCoding } from "../../FFWELReport/FFWELReportInterface";

interface FiveFWELReportCardProps {
    participantInfo: ParticipantInfo
}


export default function (props: FiveFWELReportCardProps) {
    const [loading, setLoading] = useState(true);
    const [lastTaken, setLastTaken] = useState<Date>();

    useEffect(() => {
        initialize()
        MyDataHelps.on("applicationDidBecomeVisible", initialize);
        return () => {
            MyDataHelps.off("applicationDidBecomeVisible", initialize);
        }
    }, []);

    function initialize() {
        setLoading(true);
        MyDataHelps.querySurveyAnswers({ surveyName: 'FFWEL', stepIdentifier: 'TotalWellness', limit: 1 }).then((result) => {
            if (result.surveyAnswers.length > 0) {
                setLastTaken(parseISO(result.surveyAnswers[0].date));
            }
            setLoading(false);
        })
    }

    function getInterpretation() {
        var color = getScoreColorCoding('score', parseFloat(props.participantInfo.customFields['5FWEL_score']), props.participantInfo.demographics.gender);
        if (color === 'optimal') {
            return 'Optimal wellness';
        }
        else if (color === 'warning') {
            return 'Some areas to improve wellness';
        }
        else {
            return 'Many areas to improve wellness';
        }
    }


    return (
        <ReportCard
            loading={loading}
            label="Wellness Score"
            interpretation={getInterpretation()}
            score={parseFloat(props.participantInfo.customFields['5FWEL_score']).toFixed(2)}
            lastTaken={lastTaken}
        />
    );
}