import { useState, useEffect } from 'react'
import MyDataHelps, { Guid, SurveyTask, SurveyTaskQueryParameters, SurveyTaskStatus } from "@careevolution/mydatahelps-js"
import parseISO from 'date-fns/parseISO'
import { CardTitle, LoadingIndicator, SingleSurveyTask } from '@careevolution/mydatahelps-ui';

export interface SurveyTaskListProps {
	status: SurveyTaskStatus,
	limit?: number,
	title?: string,
	onDetailLinkClick?: Function,
	previewState?: SurveyTaskListListPreviewState
}

export type SurveyTaskListListPreviewState = "IncompleteTasks" | "CompleteTasks";

export default function (props: SurveyTaskListProps) {
	const [loading, setLoading] = useState(true);
	const [tasks, setTasks] = useState<SurveyTask[] | null>(null);

	useEffect(() => {
		initialize()
		MyDataHelps.on("applicationDidBecomeVisible", initialize);
		return () => {
			MyDataHelps.off("applicationDidBecomeVisible", initialize);
		}
	}, []);

	function initialize() {
		var loadData = function () {
			var allTasks: SurveyTask[] = [];
			var makeRequest = function (pageID: Guid | null) {
				var parameters: SurveyTaskQueryParameters = { status: props.status }
				if (pageID) {
					parameters.pageID = pageID;
				}

				return MyDataHelps.querySurveyTasks(parameters).then(function (result) {
					allTasks = allTasks.concat((result as any).surveyTasks);
					if (result.nextPageID) {
						makeRequest(result.nextPageID);
					} else {
						//sort by due date for incomplete tasks
						if (props.status == "incomplete") {
							allTasks.sort((a, b) => {
								if (parseISO(a.dueDate) > parseISO(b.dueDate)) { return 1; }
								if (parseISO(a.dueDate) < parseISO(b.dueDate)) { return -1; }
								return 0;
							});
						}

						//sort by completed date for complete tasks
						if (props.status == "complete") {
							allTasks.sort((a, b) => {
								if (!a.endDate || !b.endDate) { return 0; }
								if (parseISO(a.endDate) > parseISO(b.endDate)) { return -1; }
								if (parseISO(a.endDate) < parseISO(b.endDate)) { return 1; }
								return 0;
							});
						}

						var filteredTasks = allTasks.filter(t => {
							var shouldShow = true;
							try {
								var metadata = JSON.parse(t.surveyDescription || '');
								if (metadata.training) {
									shouldShow = false;
								}
							}
							finally {
								return shouldShow;
							}
						});
                        setTasks(filteredTasks);
						setLoading(false);
					}
				});
			}
			makeRequest(null);
		}
		loadData();
	}

	if (props.status == "complete" && !tasks?.length) {
		return null;
	}

	return (
		<div className="mdhui-survey-task-list">
			{props.title &&
			//TODO language["view-all"]
				<CardTitle title={props.title} detailLinkText={props.onDetailLinkClick ? "View All" + " (" + (tasks?.length ?? 0) + ")" : undefined} onDetailClick={props.onDetailLinkClick} />
			}
			{loading && !tasks &&
				<LoadingIndicator />
			}
			{!tasks?.length && !loading && //TODO language["all-tasks-complete"]
				<div className="empty-message">{"All Tasks Complete"}</div>
			}
			{tasks?.slice(0, props.limit).map((task) =>
				<SingleSurveyTask key={task.id.toString()} task={task} onClick={() => MyDataHelps.startSurvey(task.surveyName)} surveyActive={loading} />
			)}
		</div>
	);
}