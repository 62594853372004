import MyDataHelps, { ParticipantInfo } from "@careevolution/mydatahelps-js";
import ReportCard from "./ReportCard";
import { useEffect, useState } from "react";
import parseISO from "date-fns/parseISO";

interface PSQIReportCardProps {
    participantInfo: ParticipantInfo
}

export default function (props: PSQIReportCardProps) {
    let [loading, setLoading] = useState(true);
    let [lastTaken, setLastTaken] = useState<Date>();

    function initialize() {
        setLoading(true);
        MyDataHelps.querySurveyAnswers({
            surveyName: 'PSQI',
            limit: 1
        }).then((lastSurveyResult) => {
            if (lastSurveyResult.surveyAnswers.length) {
                setLastTaken(parseISO(lastSurveyResult.surveyAnswers[0].date))
            }
            setLoading(false);
        });
    }

    useEffect(() => {
        initialize()
        MyDataHelps.on("applicationDidBecomeVisible", initialize);
        return () => {
            MyDataHelps.off("applicationDidBecomeVisible", initialize);
        }
    }, []);

    function getInterpretation() {
        var sleepValue = parseInt(props.participantInfo.customFields['PSQI_score']);
        if (sleepValue > 10) {
            return "Very poor sleep quality";
        }
        else if (sleepValue >= 5) {
            return "Poor sleep quality";
        }
        else {
            return "Good sleep quality"
        }
    }

    return (
        <ReportCard
            label="Sleep Quality"
            loading={loading}
            lastTaken={lastTaken}
            score={props.participantInfo.customFields['PSQI_score']}
            interpretation={getInterpretation()}
        />
    );
}