import { useEffect, useState } from 'react';
import './ParticipantAdministrationReport.css'
import MyDataHelps, { ParticipantInfo, SurveyAnswer, SurveyAnswersPage } from '@careevolution/mydatahelps-js';
import { Layout, LoadingIndicator, queryDailyData } from '@careevolution/mydatahelps-ui';
import parseISO from 'date-fns/parseISO';
import FFWELSummaryTable from '../FFWELReport/FFWELSummaryTable';
import FFWELBreakdownTable from '../FFWELReport/FFWELBreakdownTable';
import { FiveFWELResults, FiveFWELScore, getScoreColorCoding } from '../FFWELReport/FFWELReportInterface';
import { DailyDataType } from '@careevolution/mydatahelps-ui';
import subDays from 'date-fns/subDays';
import { DailyDataQueryResult } from '@careevolution/mydatahelps-ui';
import isWithinInterval from 'date-fns/isWithinInterval';
import { sum } from 'lodash';

type SurveyAnswerLookup = { [key: string]: SurveyAnswer };

export default function () {
  const [participantInfo, setParticipantInfo] = useState<ParticipantInfo>();
  const [answersLookup, setAnswersLookup] = useState<SurveyAnswerLookup>({});
  const [heartRateData, setHeartRateData] = useState<DailyDataQueryResult>({});
  const [stepData, setStepData] = useState<DailyDataQueryResult>({});
  const [sleepData, setSleepData] = useState<DailyDataQueryResult>({});
  const [activeMinutesData, setActiveMinutesData] = useState<DailyDataQueryResult>({});
  const [caloriesData, setCaloriesData] = useState<DailyDataQueryResult>({});

  useEffect(() => {
    initialize()
    MyDataHelps.on("applicationDidBecomeVisible", initialize);
    return () => {
      MyDataHelps.off("applicationDidBecomeVisible", initialize);
    }
  }, []);

  function parseSurveyAnswerQuery(result: SurveyAnswersPage, lookup: SurveyAnswerLookup) {
    result.surveyAnswers.forEach((answer) => {
      var key = answer.stepIdentifier + "-" + answer.resultIdentifier;
      if (!lookup[key]) {
        lookup[key] = answer;
      }
    })
  }

  function initialize() {
    var today = new Date();
    var twoMonthsPrevious = subDays(today, 60);
    Promise.all([
      MyDataHelps.getParticipantInfo(),
      MyDataHelps.querySurveyAnswers({ surveyName: 'MHI' }),
      MyDataHelps.querySurveyAnswers({ surveyName: 'W55DEM', stepIdentifier: 'nest_W55DEM_18_20' }),
      MyDataHelps.querySurveyAnswers({ surveyName: 'MEDQ',
        stepIdentifier: [
          'nest_MEDQ_04_13',
          'nest_MEDQ_14_23',
          'nest_MEDQ_24_33',
          'nest_MEDQ_34_43',
          'nest_MEDQ_44_53',
          'nest_MEDQ_54_63',
          'nest_MEDQ_64_73',
          'nest_MEDQ_74_83',
          'nest_MEDQ_84_93',
          'nest_MEDQ_94_103']
      }),
      MyDataHelps.querySurveyAnswers({ surveyName: 'GAQ' }),
      MyDataHelps.querySurveyAnswers({ surveyName: 'SCRN14', resultIdentifier: ['SCRN14_17', 'SCRN14_11', 'SCRN14_12'] }),
      MyDataHelps.querySurveyAnswers({ surveyName: 'DHLS', stepIdentifier: 'DHLS_score', limit: 1 }),
      MyDataHelps.querySurveyAnswers({ surveyName: 'SFACE', stepIdentifier: 'SFACE_score', limit: 1 }),
      MyDataHelps.querySurveyAnswers({ surveyName: 'GSE', stepIdentifier: 'GSE_score', limit: 1 }),
      MyDataHelps.querySurveyAnswers({ surveyName: 'SRRS' }),
      MyDataHelps.querySurveyAnswers({ surveyName: 'PSQI' }),
      MyDataHelps.querySurveyAnswers({ surveyName: 'NVS', stepIdentifier: 'NVS_score', limit: 1 }),
      MyDataHelps.querySurveyAnswers({ surveyName: 'WHODAS' }),
      MyDataHelps.querySurveyAnswers({ surveyName: 'FFWEL', stepIdentifier: 'TotalWellness', limit: 1 }),
      MyDataHelps.querySurveyAnswers({ surveyName: 'goalSettingSurvey', stepIdentifier: 'GOALS_01', limit: 1 }),
      queryDailyData(
        DailyDataType.FitbitRestingHeartRate, twoMonthsPrevious, today
      ),
      queryDailyData(
        DailyDataType.FitbitSteps, twoMonthsPrevious, today
      ),
      queryDailyData(
        DailyDataType.FitbitSleepMinutes, twoMonthsPrevious, today
      ),
      queryDailyData(
        DailyDataType.FitbitActiveMinutes, twoMonthsPrevious, today
      ),
      queryDailyData(
        DailyDataType.FitbitCaloriesBurned, twoMonthsPrevious, today
      ),
    ]).then(([participantInfoResult, mhiResult, w55DEMResult, medqResult, gaqResult, scrn14Result, dhlsResult, sfaceResult, gseResult, srrsResult, pqsiResult, nvsResult, whodasResult, ffwelResult, goalsResult,
      fitbitRestingHeartRateResult, fitbitStepsResult, fitbitSleepResult, fitbitActiveMinutesResult, fitbitCaloriesResult
    ]) => {
      setParticipantInfo(participantInfoResult);
      var lookup: SurveyAnswerLookup = {};
      parseSurveyAnswerQuery(mhiResult, lookup);
      parseSurveyAnswerQuery(w55DEMResult, lookup);
      parseSurveyAnswerQuery(medqResult, lookup);
      parseSurveyAnswerQuery(gaqResult, lookup);
      parseSurveyAnswerQuery(scrn14Result, lookup);
      parseSurveyAnswerQuery(dhlsResult, lookup);
      parseSurveyAnswerQuery(sfaceResult, lookup);
      parseSurveyAnswerQuery(gseResult, lookup);
      parseSurveyAnswerQuery(srrsResult, lookup);
      parseSurveyAnswerQuery(pqsiResult, lookup);
      parseSurveyAnswerQuery(nvsResult, lookup);
      parseSurveyAnswerQuery(whodasResult, lookup);
      parseSurveyAnswerQuery(ffwelResult, lookup);
      parseSurveyAnswerQuery(goalsResult, lookup);
      setAnswersLookup(lookup);

      setHeartRateData(fitbitRestingHeartRateResult);
      setStepData(fitbitStepsResult);
      setSleepData(fitbitSleepResult);
      setActiveMinutesData(fitbitActiveMinutesResult);
      setCaloriesData(fitbitCaloriesResult);
    });
  }

  function calculateDeviceDataStatistics(data: DailyDataQueryResult) : [lastMonthAverage: number, lastMonthDelta: number, lastWeekAverage: number] {
    var days = Object.keys(data);

    var lastMonthData: number[] = [];
    var olderThanLastMonthData: number[] = [];
    var lastWeekData: number[] = [];

    var today = new Date();
    var oneMonthAgo = subDays(today, 30);
    var twoMonthsAgo = subDays(today, 60);
    var lastWeek = subDays(today, 7);
    var weekInterval = { start: lastWeek, end: today };
    var monthInterval = { start: oneMonthAgo, end: today };
    var twoMonthInterval = { start: twoMonthsAgo, end: oneMonthAgo };

    days.forEach((day) => {
      var date = parseISO(day);
      if (isWithinInterval(date, weekInterval)) {
        lastWeekData.push(data[day]);
      }
      if (isWithinInterval(date, monthInterval)) {
        lastMonthData.push(data[day]);
      }
      if (isWithinInterval(date, twoMonthInterval)) {
        olderThanLastMonthData.push(data[day]);
      }
    });

    var lastWeekAverage = sum(lastWeekData) / lastWeekData.length;
    var lastMonthAverage = sum(lastMonthData) / lastMonthData.length;
    var twoMonthAverage = sum(olderThanLastMonthData) / olderThanLastMonthData.length;

    return [lastMonthAverage, lastMonthAverage - twoMonthAverage, lastWeekAverage];
  }

  if (!participantInfo) {
    return <Layout colorScheme='light'><LoadingIndicator/></Layout>
  }

  var now = new Date().getTime();
  var dob = Date.parse(participantInfo.demographics.dateOfBirth);
  var age = ((now - dob) / 31556952000).toFixed(0);

  function surveyAnswerTimestamp(key: string) {
    return parseISO(answersLookup[key]?.date);
  }
  function surveyAnswerOrEmpty(key: string) {
    return answersLookup[key]?.answers[0] || "";
  }
  function surveyAnswersOrEmpty(key: string) {
    return answersLookup[key]?.answers.join(', ') || "";
  }

  function getConditions() {
    var conditions = [
      'nest_MHI_13-MHI_13',
      'nest_MHI_13-MHI_13Other',
      'nest_MHI_14-MHI_14',
      'nest_MHI_14-MHI_14Other',
      'nest_MHI_15-MHI_15',
      'nest_MHI_15-MHI_15Other',
      'nest_MHI_16-MHI_16',
      'nest_MHI_16-MHI_16Other',
      'nest_MHI_17-MHI_17',
      'nest_MHI_17-MHI_17Other',
      'nest_MHI_18-MHI_18',
      'nest_MHI_18-MHI_18Other',
      'nest_MHI_19-MHI_19',
      'nest_MHI_19-MHI_19Other',
      'nest_MHI_20-MHI_20',
      'nest_MHI_20-MHI_20Other',
      'nest_MHI_21-MHI_21',
      'nest_MHI_21-MHI_21Other',
      'nest_MHI_22-MHI_22',
      'nest_MHI_22-MHI_22Other',
      'nest_MHI_23-MHI_23',
      'nest_MHI_23-MHI_23Other',
      'nest_MHI_24-MHI_24',
      'nest_MHI_24-MHI_24Other',
      'nest_MHI_25-MHI_25',
      'nest_MHI_25-MHI_25Other',
      'nest_MHI_26-MHI_26',
      'nest_MHI_26-MHI_26Other',
    ].map((key) => surveyAnswerOrEmpty(key)).filter((a) => a !== "" && a !== 'None of the above');

    return conditions.join(', ');
  }

  function getMedicationModel(base: string, name: string, doseA: string, doseB: string, frequency: string, indication: string, start: string, end: string) {
    return {
      name: surveyAnswerOrEmpty(`${base}-${name}`),
      doseA: surveyAnswerOrEmpty(`${base}-${doseA}`),
      doseB: surveyAnswerOrEmpty(`${base}-${doseB}`),
      frequency: surveyAnswersOrEmpty(`${base}-${frequency}`),
      indication: surveyAnswerOrEmpty(`${base}-${indication}`),
      start: surveyAnswerOrEmpty(`${base}-${start}`),
      end: surveyAnswerOrEmpty(`${base}-${end}`),
    };
  }

  function getMedicationModels() {
    return [
      ['nest_MEDQ_04_13', 'MEDQ_04', 'MEDQ_05', 'MEDQ_06', 'MEDQ_07', 'MEDQ_08', 'MEDQ_09', 'MEDQ_10'],
      ['nest_MEDQ_14_23', 'MEDQ_14', 'MEDQ_15', 'MEDQ_16', 'MEDQ_17', 'MEDQ_18', 'MEDQ_19', 'MEDQ_20'],
      ['nest_MEDQ_24_23', 'MEDQ_24', 'MEDQ_25', 'MEDQ_26', 'MEDQ_27', 'MEDQ_28', 'MEDQ_29', 'MEDQ_30'],
      ['nest_MEDQ_34_43', 'MEDQ_34', 'MEDQ_35', 'MEDQ_36', 'MEDQ_37', 'MEDQ_38', 'MEDQ_39', 'MEDQ_40'],
      ['nest_MEDQ_44_53', 'MEDQ_44', 'MEDQ_45', 'MEDQ_46', 'MEDQ_47', 'MEDQ_48', 'MEDQ_49', 'MEDQ_50'],
      ['nest_MEDQ_54_63', 'MEDQ_54', 'MEDQ_15', 'MEDQ_56', 'MEDQ_57', 'MEDQ_58', 'MEDQ_59', 'MEDQ_60'],
      ['nest_MEDQ_64_73', 'MEDQ_64', 'MEDQ_65', 'MEDQ_66', 'MEDQ_67', 'MEDQ_68', 'MEDQ_69', 'MEDQ_70'],
      ['nest_MEDQ_74_83', 'MEDQ_74', 'MEDQ_75', 'MEDQ_76', 'MEDQ_77', 'MEDQ_78', 'MEDQ_79', 'MEDQ_80'],
      ['nest_MEDQ_84_93', 'MEDQ_84', 'MEDQ_85', 'MEDQ_86', 'MEDQ_87', 'MEDQ_88', 'MEDQ_89', 'MEDQ_90'],
      ['nest_MEDQ_94_103', 'MEDQ_94', 'MEDQ_95', 'MEDQ_96', 'MEDQ_97', 'MEDQ_98', 'MEDQ_99', 'MEDQ_100']
    ].map(
      (a) => getMedicationModel(a[0], a[1], a[2], a[3], a[4], a[5], a[6], a[7])
    ).filter(
      (m) => m.name != ""
    );
  }

  function getSCRN14Flags() {
    var concerns = [];
    var scrn11 = surveyAnswerOrEmpty('nest_SCRN14_11_13-SCRN14_11');
    var scrn12 = surveyAnswerOrEmpty('nest_SCRN14_11_13-SCRN14_12');
    if (scrn11 !== '4' && scrn11 !== '3') {
      concerns.push('swallowing');
    }
    if (scrn12 !== '4' && scrn12 !== '3') {
      concerns.push('chewing');
    }

    if (concerns.length) {
      return `Participant may have concers related to ability to eat. Specifically: ${concerns.join(', ')}`;
    }

    return "";
  }

  function getMHIFlags() {
    var concerns = [];
    var mhi04 = surveyAnswerOrEmpty('nest_MHI_04_08-MHI_04');
    var mhi66 = surveyAnswerOrEmpty('nest_MHI_56_71-MHI_66');
    if (mhi04 === 'False') {
      concerns.push('Participant has no PHCP');
    }
    if (mhi66 === 'True') {
      concerns.push('Review responses to questions regarding alcohol consumption');
    }

    return concerns;
  }

  function getMHIGoals() {
    var goals = [];
    var mhi39 = surveyAnswerOrEmpty('nest_MHI_31_46-MHI_39');
    var mhi55 = surveyAnswerOrEmpty('nest_MHI_47_55-MHI_55');
    var mhi71 = surveyAnswerOrEmpty('nest_MHI_56_71-MHI_71');
    var mhi86 = surveyAnswerOrEmpty('nest_MHI_72_86-MHI_86');
    var mhi90 = surveyAnswerOrEmpty('nest_MHI_87_90-MHI_90');
    if (mhi39 === 'True') {
      goals.push('Quit smoking');
    }
    if (mhi55 === 'True' ||
        mhi71 === 'True' ||
        mhi86 === 'True' ||
        mhi90 === 'True') {
      goals.push('Cut back on substance use');
    }

    return goals;
  }

  function getCSEPFlags() {
    var concerns: string[] = [];
    var concernCheck = [
      {
        text: 'A diagnosis of/treatment for heart disease or stroke, or pain/discomfort/pressure in your chest during activities of daily living or during physical activity?',
        key: 'nest_GAQ_01_06-GAQ_01'
      },
      {
        text: 'A diagnosis of/treatment for high blood pressure (BP), or a resting BP of 160/90 mmHg or higher?',
        key: 'nest_GAQ_01_06-GAQ_02'
      },
      {
        text: 'Dizziness or lightheadedness during physical activity?',
        key: 'nest_GAQ_01_06-GAQ_03'
      },
      {
        text: 'Shortness of breath at rest?',
        key: 'nest_GAQ_01_06-GAQ_04'
      },
      {
        text: 'Loss of consciousness/fainting for any reason?',
        key: 'nest_GAQ_01_06-GAQ_05'
      },
      {
        text: 'Concussion?',
        key: 'nest_GAQ_01_06-GAQ_06'
      },
      {
        text: 'Do you currently have pain or swelling in any part of your body (such as from an injury, acute flare-up of arthritis, or back pain) that affects your ability to be physically active?',
        key: 'nest_GAQ_07_09-GAQ_07'
      },
      {
        text: 'Has a health care provider told you that you should avoid or modify certain types of physical activity?',
        key: 'nest_GAQ_07_09-GAQ_08'
      },
      {
        text: 'Do you have any other medical or physical condition (such as diabetes, cancer, osteoporosis, asthma, spinal cord injury) that may affect your ability to be physically active?',
        key: 'nest_GAQ_07_09-GAQ_09'
      },
    ];

    concernCheck.forEach((c) => {
      if (surveyAnswerOrEmpty(c.key) === 'True') {
        concerns.push(c.text);
      }
    })

    return concerns;
  }

  function getCSEPGoals() {
    var goals = [];
    var gaq12 = surveyAnswerOrEmpty('nest_GAQ_10_12-GAQ_12');
    var gaq12Number = parseInt(gaq12);
    if (gaq12Number < 150) {
      goals.push('Improve your physical wellness');
    }

    return goals;
  }

  function getGSEGoals() {
    var goals = [];
    var score = surveyAnswerOrEmpty('GSE_score-GSE_score');
    var scoreNumber = parseInt(score);
    if (scoreNumber >= 20) {
      goals.push('Improve your stress management');
    }

    return goals;
  }

  function getSRRSFlags() {
    if (!!participantInfo && parseInt(participantInfo.customFields['SRRS_score']) > 300) {
      var srrs1 = surveyAnswersOrEmpty('SRRS_01-SRRS_01');
      var srrs2 = surveyAnswersOrEmpty('SRRS_02-SRRS_02');
      var srrs3 = surveyAnswersOrEmpty('SRRS_03-SRRS_03');

      var answers = [srrs1, srrs2, srrs3].join(', ');
      return 'Participant checked off the following items: ' + answers;
    }

    return "";
  }

  function getSRRSGoals() {
    var goals = [];
    var srrs1 = surveyAnswersOrEmpty('SRRS_01-SRRS_01');
    var srrs2 = surveyAnswersOrEmpty('SRRS_02-SRRS_02');
    var srrs3 = surveyAnswersOrEmpty('SRRS_03-SRRS_03');

    var answers = [srrs1, srrs2, srrs3].join(', ').split(', ');

    if (
      answers.indexOf('Divorce') >= 0 ||
      answers.indexOf('Marital separation from mate') >= 0 ||
      answers.indexOf('Marriage') >= 0 ||
      answers.indexOf('Marital reconciliation with mate') >= 0 ||
      answers.indexOf('Sexual difficulties') >= 0 ||
      answers.indexOf('Gaining a new family member') >= 0 ||
      answers.indexOf('Major change in the number of spousal arguments') >= 0 ||
      answers.indexOf('Major change in number of family get-togethers') >= 0 
    ) {
      goals.push('Improve your love wellness');
    }

    if(
      answers.indexOf('Being fired at work') >= 0 ||
      answers.indexOf('Retirement from work') >= 0 ||
      answers.indexOf('Major business readjustment') >= 0 ||
      answers.indexOf('Changing to a different line of work') >= 0 ||
      answers.indexOf('Major change in responsibilities at work') >= 0 ||
      answers.indexOf('Troubles with the boss') >= 0 ||
      answers.indexOf('Major changes in working hours or condition') >= 0
    ) {
      goals.push('Increase your work wellness')
    }

    if (answers.indexOf('Major change in sleeping habits') >= 0){
      goals.push('Improve your sleep');
    }

    if (
      answers.indexOf('In-law troubles') >= 0 ||
      answers.indexOf('Major change in usual type/amount of recreation') >= 0 ||
      answers.indexOf('Major change in church activity') >= 0 ||
      answers.indexOf('Major change in social activities') >= 0
    ) {
      goals.push('Increase your friendship wellness');
    }

    if (answers.indexOf('Major change in eating habits') >= 0) {
      goals.push('Improve your nutrition wellness');
    }

    if (!!participantInfo && parseInt(participantInfo.customFields['SRRS_score']) > 150) {
      goals.push('Improve your stress management');
    }

    return goals;
  }

  function getPSQIFlags() {
    if (!!participantInfo && parseInt(participantInfo.customFields['PSQI_score']) > 10) {
      return 'Review responses to evaluate high score';
    }

    return "";
  }

  function getPSQIGoals() {
    if (!!participantInfo && parseInt(participantInfo.customFields['PSQI_score']) >= 5) {
      return 'Improve your sleep';
    }

    return "";
  }

  function getWHODASGoals() {
    var goals: string[] = [];
    if ([
      'nest_WHODAS_01_06-WHODAS_01',
      'nest_WHODAS_01_06-WHODAS_02',
      'nest_WHODAS_01_06-WHODAS_03',
      'nest_WHODAS_01_06-WHODAS_04',
      'nest_WHODAS_01_06-WHODAS_05',
    ].some((k) => { return parseInt(surveyAnswerOrEmpty(k)) >= 2 })) {
      goals.push('Improve your thinking')
    }

    if ([
      'nest_WHODAS_07_11-WHODAS_07',
      'nest_WHODAS_07_11-WHODAS_08',
      'nest_WHODAS_07_11-WHODAS_09',
      'nest_WHODAS_07_11-WHODAS_10',
      'nest_WHODAS_07_11-WHODAS_11',
    ].some((k) => { return parseInt(surveyAnswerOrEmpty(k)) >= 2 })) {
      goals.push('Improve your physical wellness')
    }

    if (parseInt(surveyAnswerOrEmpty('nest_WHODAS_12_15-WHODAS_14')) >= 2) {
      goals.push('Improve your nutrition wellness');
    }

    if ([
      'nest_WHODAS_01_06-WHODAS_06',
      'nest_WHODAS_16_20-WHODAS_16',
      'nest_WHODAS_16_20-WHODAS_17',
      'nest_WHODAS_16_20-WHODAS_18',
      'nest_WHODAS_16_20-WHODAS_19',
      'nest_WHODAS_29_36-WHODAS_29',
    ].some((k) => { return parseInt(surveyAnswerOrEmpty(k)) >= 2 })) {
      goals.push('Increase your friendship wellness')
    }

    if ([
      'nest_WHODAS_16_20-WHODAS_18',
      'nest_WHODAS_16_20-WHODAS_20',
      'nest_WHODAS_29_36-WHODAS_35',
    ].some((k) => { return parseInt(surveyAnswerOrEmpty(k)) >= 2 })) {
      goals.push('Increase your love wellness')
    }

    if (parseInt(surveyAnswerOrEmpty('nest_WHODAS_29_36-WHODAS_33')) >= 2) {
      goals.push('Improve the experiencing of your emotions');
    }

    if (parseInt(surveyAnswerOrEmpty('nest_WHODAS_29_36-WHODAS_36')) >= 2) {
      goals.push('Improve your leisure wellness');
    }

    return goals;
  }


  function getFFWELGoals() {
    var goals: string[] = [];
    if (!participantInfo) return goals;

    if (participantInfo.customFields['5FWEL_thinking']) { goals.push('Improve your thinking'); }
    if (participantInfo.customFields['5FWEL_emotions']) { goals.push('Improve the experiencing of your emotions'); }
    if (participantInfo.customFields['5FWEL_control']) { goals.push('Increase your sense of control'); }
    if (participantInfo.customFields['5FWEL_work']) { goals.push('Increase your work wellness'); }
    if (participantInfo.customFields['5FWEL_positiveHumour']) { goals.push('Increase your positive humour'); }
    if (participantInfo.customFields['5FWEL_leisure']) { goals.push('Increase your leisure wellness'); }
    if (participantInfo.customFields['5FWEL_stressManagement']) { goals.push('Improve your stress management'); }
    if (participantInfo.customFields['5FWEL_selfWorth']) { goals.push('Increase your self worth'); }
    if (participantInfo.customFields['5FWEL_realisticBeliefs']) { goals.push('Improve your realistic beliefs'); }
    if (participantInfo.customFields['5FWEL_friendship']) { goals.push('Increase your friendship wellness'); }
    if (participantInfo.customFields['5FWEL_love']) { goals.push('Improve your love wellness'); }
    if (participantInfo.customFields['5FWEL_spirituality']) { goals.push('Increase your spiritual wellness'); }
    if (participantInfo.customFields['5FWEL_genderIdentity']) { goals.push('Enhance your gender identity'); }
    if (participantInfo.customFields['5FWEL_culturalIdentity']) { goals.push('Enhance your cultural identity'); }
    if (participantInfo.customFields['5FWEL_selfCare']) { goals.push('Improve your self care'); }
    if (participantInfo.customFields['5FWEL_physical']) { goals.push('Improve your physical wellness'); }
    if (participantInfo.customFields['5FWEL_nutrition']) { goals.push('Improve your nutrition wellness'); }

    return goals;
  }

  function getFFWELScore(key: string, score: number, gender: string): FiveFWELScore {
    return {
      score,
      coding: getScoreColorCoding(key, score, gender)
    }
  }

  function getFFWELModel() : FiveFWELResults | null {
    if (!participantInfo) return null;

    var gender = participantInfo?.demographics.gender;
    return {
      timestamp: surveyAnswerTimestamp('TotalWellness-TotalWellness'),
      score: getFFWELScore('score', parseFloat(participantInfo?.customFields['5FWEL_score']), gender),
      creativeSelfScore: getFFWELScore('creativeSelfScore', parseFloat(participantInfo?.customFields['5FWEL_creativeSelf_score']), gender),
      copingSelfScore: getFFWELScore('copingSelfScore', parseFloat(participantInfo?.customFields['5FWEL_copingSelf_score']), gender),
      socialSelfScore: getFFWELScore('socialSelfScore', parseFloat(participantInfo?.customFields['5FWEL_socialSelf_score']), gender),
      essentialSelfScore: getFFWELScore('essentialSelfScore', parseFloat(participantInfo?.customFields['5FWEL_essentialSelf_score']), gender),
      physicalSelfScore: getFFWELScore('physicalSelfScore', parseFloat(participantInfo?.customFields['5FWEL_physicalSelf_score']), gender),
      thinking: getFFWELScore('thinking', parseFloat(participantInfo?.customFields['5FWEL_thinking']), gender),
      emotions: getFFWELScore('emotions', parseFloat(participantInfo?.customFields['5FWEL_emotions']), gender),
      control: getFFWELScore('control', parseFloat(participantInfo?.customFields['5FWEL_control']), gender),
      work: getFFWELScore('work', parseFloat(participantInfo?.customFields['5FWEL_work']), gender),
      positiveHumor: getFFWELScore('positiveHumor', parseFloat(participantInfo?.customFields['5FWEL_positiveHumor']), gender),
      leisure: getFFWELScore('leisure', parseFloat(participantInfo?.customFields['5FWEL_leisure']), gender),
      selfWorth: getFFWELScore('selfWorth', parseFloat(participantInfo?.customFields['5FWEL_selfWorth']), gender),
      realisticBeliefs: getFFWELScore('realisticBeliefs', parseFloat(participantInfo?.customFields['5FWEL_realisticBeliefs']), gender),
      stressManagement: getFFWELScore('stressManagement', parseFloat(participantInfo?.customFields['5FWEL_stressManagement']), gender),
      friendship: getFFWELScore('friendship', parseFloat(participantInfo?.customFields['5FWEL_friendship']), gender),
      love: getFFWELScore('love', parseFloat(participantInfo?.customFields['5FWEL_love']), gender),
      spirituality: getFFWELScore('spirituality', parseFloat(participantInfo?.customFields['5FWEL_spirituality']), gender),
      genderIdentity: getFFWELScore('genderIdentity', parseFloat(participantInfo?.customFields['5FWEL_genderIdentity']), gender),
      culturalIdentity: getFFWELScore('culturalIdentity', parseFloat(participantInfo?.customFields['5FWEL_culturalIdentity']), gender),
      selfCare: getFFWELScore('selfCare', parseFloat(participantInfo?.customFields['5FWEL_selfCare']), gender),
      exercise: getFFWELScore('exercise', parseFloat(participantInfo?.customFields['5FWEL_exercise']), gender),
      nutrition: getFFWELScore('nutrition', parseFloat(participantInfo?.customFields['5FWEL_nutrition']), gender),
    };
  }

  var ffwelModel = getFFWELModel();
  var [rhrMonthAvg, rhrDelta, rhrWeekAvg] = calculateDeviceDataStatistics(heartRateData);
  var [stepMonthAvg, stepDelta, stepWeekAvg] = calculateDeviceDataStatistics(stepData);
  var [sleepMonthAvg, sleepDelta, sleepWeekAvg] = calculateDeviceDataStatistics(sleepData);
  var [activeMonthAvg, activeDelta, activeWeekAvg] = calculateDeviceDataStatistics(activeMinutesData);
  var [caloriesMonthAvg, caloriesDelta, caloriesWeekAvg] = calculateDeviceDataStatistics(caloriesData);

  return (
    <Layout colorScheme='light' className='participant-administration-report'>
    <div className="step-container">
        <div>Report Date: {new Date().toLocaleDateString()}</div>
        <div className="report-section">
            <div><b>Participant Information:</b></div>
          <div>Name: {participantInfo.demographics.firstName} {participantInfo.demographics.lastName}</div>
            <div>Age: {age}</div>
            <div>Community Hub: {participantInfo.customFields['communityHub']}</div>
            <div>Contact Information: {participantInfo.demographics.email}</div>
        </div>

        <div className="report-section">
            <div><b>Current Healthcare Providers:</b></div>
            <div>Primary Health Care Provider: {surveyAnswerOrEmpty('nest_MHI_04_08-MHI_05')}</div>
            <div>Health care provider consulted most regularly: {surveyAnswerOrEmpty('nest_W55DEM_18_20-W55DEM_20')}</div>
            <div>Specialist: {surveyAnswerOrEmpty('nest_MHI_04_08-MHI_08')} Specialty: {surveyAnswerOrEmpty('nest_MHI_04_08-MHI_07')}</div>
        </div>

        <div className="report-section">
            <div><b>Medical History:</b></div>
            <div>Allergies: {surveyAnswerOrEmpty('nest_MHI_09_12-MHI_10')}, {surveyAnswerOrEmpty('nest_MHI_09_12-MHI_12')}</div>
            <div>Medical Conditions: {getConditions()}</div>
            <div>Surgical History and Trauma: {surveyAnswerOrEmpty('nest_MHI_27_30-MHI_28')}, {surveyAnswerOrEmpty('nest_MHI_27_30-MHI_30')}</div>
        </div>

        <div className="report-section">
            <div><b>Current Medications:</b></div>
            <table>
                <thead>
                    <tr>
                        <th>Medication Name</th>
                        <th>Dosage</th>
                        <th>Frequency</th>
                        <th>Indication</th>
                        <th>Date Started / Stopped</th>
                    </tr>
                </thead>
                <tbody id="medication-table">
              {getMedicationModels().map((row) => {
                return (
                  <tr key={row.name}>
                    <td>{row.name}</td>
                    <td>{row.doseA}, {row.doseB}</td>
                    <td>{row.frequency}</td>
                    <td>{row.indication}</td>
                    <td>{row.start} / {row.end}</td>
                  </tr>);
              })}
            </tbody>
          </table>
        </div>

        <div className="report-section">
            <div><b>Substance Use:</b></div>
            <table>
                <thead>
                    <tr>
                        <th colSpan={3}>Cigarettes</th>
                    </tr>
                    <tr>
                        <th>Current Use</th>
                        <th>Past Use</th>
                        <th>Interest in<br/>Cutting Back</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>
                  In the past 30 days, how often did you smoke cigarettes? {surveyAnswerOrEmpty('nest_MHI_31_46-MHI_34')}<br />
                            How many cigarettes do you usually smoke each day? {surveyAnswerOrEmpty('nest_MHI_31_46-MHI_35')}<br />
                            In the past 30 days, how many days have you smoked one or more cigarettes? {surveyAnswerOrEmpty('nest_MHI_31_46-MHI_36')}<br />
                            On the days that you smoke, how many cigarettes do you usually smoke? {surveyAnswerOrEmpty('nest_MHI_31_46-MHI_37')}<br />
                        </td>
                        <td>
                            Have you ever smoked cigarettes daily? {surveyAnswerOrEmpty('nest_MHI_31_46-MHI_40')}<br />
                            What age did you begin to smoke cigarettes daily? {surveyAnswerOrEmpty('nest_MHI_31_46-MHI_41')}<br />
                            When you smoked every day, how many cigarettes did you usually smoke each day? {surveyAnswerOrEmpty('nest_MHI_31_46-MHI_42')}<br />
                            When did you stop smoking daily? {surveyAnswerOrEmpty('nest_MHI_31_46-MHI_43')}<br />
                            Was that when you completely quit smoking? {surveyAnswerOrEmpty('nest_MHI_31_46-MHI_44')}<br />
                            Have you completely quit smoking? {surveyAnswerOrEmpty('nest_MHI_31_46-MHI_45')}<br />
                            When did you stop smoking completely? {surveyAnswerOrEmpty('nest_MHI_31_46-MHI_46')}
                        </td>
                        <td>
                            {surveyAnswerOrEmpty('nest_MHI_31_46-MHI_39')}
                        </td>
                    </tr>
                </tbody>
            </table>

            <table>
                <thead>
                    <tr>
                        <th colSpan={2}>Vaping</th>
                    </tr>
                    <tr>
                        <th>Current Use</th>
                        <th>Interest in<br/>Cutting Back</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>
                            In the past 30 days, how often did you use an e-cigarette or vaping device? {surveyAnswerOrEmpty('nest_MHI_47_55-MHI_50')}<br />
                            During the past 30 days, on how many days did you vape an e-cigarette or a vaping device with nicotine? {surveyAnswerOrEmpty('nest_MHI_47_55-MHI_51')}<br />
                            During the past 30 days, on how many days did you vape an e-cigarette or a vaping device without nicotine? {surveyAnswerOrEmpty('nest_MHI_47_55-MHI_52')}<br />
                            During the past 30 days, on how many days did you vape an e-cigarette or a vaping device but did not know what it contained?  {surveyAnswerOrEmpty('nest_MHI_47_55-MHI_53')}<br />
                        </td>
                        <td>
                            {surveyAnswerOrEmpty('nest_MHI_47_55-MHI_55')}
                        </td>
                    </tr>
                </tbody>
            </table>

            <table>
                <thead>
                    <tr>
                        <th colSpan={3}>Other Substances</th>
                    </tr>
                    <tr>
                        <th>Current Use</th>
                        <th>Past Use</th>
                        <th>Interest in<br/>Cutting Back</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>
                            Do you currently consume any other substances? {surveyAnswerOrEmpty('nest_MHI_87_90-MHI_87')}<br />
                            If yes, what substance do you/have you used?  {surveyAnswerOrEmpty('nest_MHI_87_90-MHI_89')}<br />
                        </td>
                        <td>
                            Have you ever consumed any other substances? {surveyAnswerOrEmpty('nest_MHI_87_90-MHI_88')}<br />
                            If yes, what substance do you/have you used? {surveyAnswerOrEmpty('nest_MHI_87_90-MHI_89')}<br />
                        </td>
                        <td>
                            Are you interested in quitting or cutting back on your substance use? {surveyAnswerOrEmpty('nest_MHI_87_90-MHI_90')}
                        </td>
                    </tr>
                </tbody>
            </table>

            <table>
                <thead>
                    <tr>
                        <th colSpan={3}>Alcohol</th>
                    </tr>
                    <tr>
                        <th>Current Use</th>
                        <th>Past Use</th>
                        <th>Interest in<br />Cutting Back</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>
                            During the past 30 days, did you have a drink? {surveyAnswerOrEmpty('nest_MHI_56_71-MHI_63')}<br />
                            During the past 30 days, on those days when you drank alcoholic beverages, how many drinks did you usually have? {surveyAnswerOrEmpty('nest_MHI_56_71-MHI_64')}<br />
                            During the past 30 days, how often have you had 4 or more drinks on one occasion? {surveyAnswerOrEmpty('nest_MHI_56_71-MHI_65')}<br />
                            During the past 7 days, did you have a drink? {surveyAnswerOrEmpty('nest_MHI_56_71-MHI_66')}<br />
                            On how many of the past 7 days did you have a drink? {surveyAnswerOrEmpty('nest_MHI_56_71-MHI_67')}<br />
                            On how many of the past 7 days did you have 4 or more drinks? {surveyAnswerOrEmpty('nest_MHI_56_71-MHI_68')}<br />
                            
                            
                        </td>
                        <td>
                            During the past 12 months, how often did you drink alcoholic beverages? {surveyAnswerOrEmpty('nest_MHI_56_71-MHI_58')}<br />
                            During the past 12 months, on those days when you drank alcoholic beverages, how many drinks did you usually have? {surveyAnswerOrEmpty('nest_MHI_56_71-MHI_59')}<br />
                            During the past 12 months, how often have you had 4 or more drinks on one occasion? {surveyAnswerOrEmpty('nest_MHI_56_71-MHI_60')}<br />
                            During the past 12 months, where did you most often have a drink? {surveyAnswerOrEmpty('nest_MHI_56_71-MHI_61')}<br />
                        </td>
                        <td>
                            Has someone been concerned about your drinking or suggested you cut down your alcohol intake? {surveyAnswerOrEmpty('nest_MHI_56_71-MHI_69')}<br />
                            Was it in the past year? {surveyAnswerOrEmpty('nest_MHI_56_71-MHI_70')}<br />
                            Are you interested in quitting or cutting back on your alcohol use? {surveyAnswerOrEmpty('nest_MHI_56_71-MHI_71')}<br />
                        </td>
                    </tr>
                </tbody>
            </table>

            <table>
                <thead>
                    <tr>
                        <th colSpan={3}>Cannabis</th>
                    </tr>
                    <tr>
                        <th>Current Use</th>
                        <th>Past Use</th>
                        <th>Interest in<br />Cutting Back</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>
                            During the past 3 months, how often have you used cannabis? {surveyAnswerOrEmpty('nest_MHI_72_86-MHI_82')}<br />
                            During the past 30 days, how often have you used cannabis? {surveyAnswerOrEmpty('nest_MHI_72_86-MHI_83')}<br />
                            Was it for medical reasons, non-medical reasons, or both? {surveyAnswerOrEmpty('nest_MHI_72_86-MHI_75')}<br />
                            If for medical reasons, for what main symptom did you use cannabis? {surveyAnswerOrEmpty('nest_MHI_72_86-MHI_76')}<br />
                        </td>
                        <td>
                            During the past 12 months, have you used or tried cannabis? {surveyAnswerOrEmpty('nest_MHI_72_86-MHI_77')}<br />
                            During the past 12 months, for what main medical condition did you use cannabis? {surveyAnswerOrEmpty('nest_MHI_72_86-MHI_78')}<br />
                            During the past 12 months, which of the following cannabis products have you used? {surveyAnswerOrEmpty('nest_MHI_72_86-MHI_79')}<br />
                            During the past 12 months, how often have you used cannabis products?{surveyAnswerOrEmpty('nest_MHI_72_86-MHI_80')}<br />
                            In the past 12 months, which of the following methods did you use to consume cannabis? {surveyAnswerOrEmpty('nest_MHI_72_86-MHI_81')}<br />
                        </td>
                        <td>
                            Has someone been concerned about your drinking or suggested you cut down your cannabis intake? {surveyAnswerOrEmpty('nest_MHI_72_86-MHI_84')}<br />
                            Was it in the past 3 months? {surveyAnswerOrEmpty('nest_MHI_72_86-MHI_85')}<br />
                            Are you interested in quitting or cutting back on your cannabis use? {surveyAnswerOrEmpty('nest_MHI_72_86-MHI_86')}<br />
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>

        <div className="report-section">
            <div><b>1. SCREEN</b></div>
            <table>
                <tr>
                    <td>Last Completed: {surveyAnswerTimestamp('nest_SCRN14_14_17-SCRN14_17').toDateString()} </td>
                    <td>Score: {participantInfo.customFields['SCRN14_score']}</td>
                </tr>
                <tr>
                    <td>Goal(s) Triggered: </td>
                </tr>
                <tr>
                    <td>Flags: {getSCRN14Flags()} </td>
                </tr>
            </table>
        </div>

        <div className='report-section'>
            <div><b>2. Digital Health Literacy Scale</b></div>
            <table>
                <tr>
                    <td>Last Completed: {surveyAnswerTimestamp('DHLS_score-DHLS_score').toDateString()}</td>
                    <td>Score: {participantInfo.customFields['DHLS_score']}</td>
                </tr>
            </table>
        </div>

        <div className='report-section'>
            <div><b>3. Medical History Questionnaire &amp; Medication Questionnaire</b></div>
            <table>
                <tr>
                    <td>Last Completed: {surveyAnswerTimestamp('nest_MHI_87_90-MHI_87').toDateString()}</td>
                </tr>
                <tr>
                    <td>Goal(s) Triggered: {getMHIGoals().join(', ')}</td>
                </tr>
                <tr>
                    <td>Flags: {getMHIFlags().join(', ')}</td>
                </tr>
            </table>
        </div>

        <div className='report-section'>
            <div><b>4. CSEP Get Active Questionnaire</b></div>
            <table>
                <tr>
                    <td>Last Completed:  {surveyAnswerTimestamp('nest_GAQ_10_12-GAQ_12').toDateString()}</td>
                </tr>
                <tr>
                    <td>Goal(s) Triggered: {getCSEPGoals().join(', ')}</td>
                </tr>
                <tr>
                  <td>Flags: {getCSEPFlags().map((f) => { return (<div>{f}</div>); })}</td>
                </tr>
            </table>
        </div>


        <div className='report-section'>
            <div><b>5. CSEP Follow Up</b></div>
            <table>
                <tr>
                    <td>Last Completed:  {}</td>
                </tr>
                <tr>
                    <td>Goal(s) Triggered: {}</td>
                </tr>
                <tr>
                  <td>Flags: {}</td>
                </tr>
            </table>
        </div>

        <div className='report-section'>
            <div><b>6. ACE Altarum Consumer Engagement</b></div>
            <table>
                <tr>
                  <td>Last Completed:  {surveyAnswerTimestamp('SFACE_score-SFACE_score').toDateString()}</td><td>Score: {participantInfo.customFields['SFACE_score']}</td>
                </tr>
            </table>
        </div>

        <div className='report-section'>
            <div><b>7. GSE General Self-Efficacy Scale</b></div>
            <table>
                <tr>
                  <td>Last Completed:  {surveyAnswerTimestamp('GSE_score-GSE_score').toDateString()}</td><td>Score: {participantInfo.customFields['GSE_score']}</td>
                </tr>
                <tr>
                    <td>Goal(s) Triggered: {getGSEGoals().join(', ')}</td>
                </tr>
            </table>
        </div>

        <div className='report-section'>
            <div><b>8. Holmes-Rahe Stress Inventory</b></div>
            <table>
                <tr>
                  <td>Last Completed:  {surveyAnswerTimestamp('SRRS_score-SRRS_score').toDateString()}</td><td>Score: {participantInfo.customFields['SRRS_score']}</td>
                </tr>
                <tr>
                    <td>Goal(s) Triggered: {getSRRSGoals().join(', ')}</td>
                </tr>
                <tr>
                  <td>Flags: {getSRRSFlags()}</td>
                </tr>
            </table>
        </div>

        <div className='report-section'>
            <div><b>9. PSQI Pittsburgh Sleep Quality Index</b></div>
            <table>
                <tr>
                  <td>Last Completed:  {surveyAnswerTimestamp('PSQI_score-PSQI_score').toDateString()}</td><td>Score: {participantInfo.customFields['PSQI_score']}</td>
                </tr>
                <tr>
                    <td>Goal(s) Triggered: {getPSQIGoals()}</td>
                </tr>
                <tr>
                  <td>Flags: {getPSQIFlags()}</td>
                </tr>
            </table>
          <table className='psqi-table' border={1}>
            <thead>
              <tr>
                <th>
                  PSQI Component
                </th>
                <th>
                  Score (0-3)
                </th>
              </tr>
            </thead>
            <tbody>
              <tr><td>Subjective sleep quality</td><td>{participantInfo.customFields['PSQI_subjectiveSleepQuality_score']}</td></tr>
              <tr><td>Sleep latency</td><td>{participantInfo.customFields['PSQI_sleepLatency_score']}</td></tr>
              <tr><td>Sleep duration</td><td>{participantInfo.customFields['PSQI_sleepDuration_score']}</td></tr>
              <tr><td>Habitual sleep efficiency</td><td>{participantInfo.customFields['PSQI_habitualSleepEffciency_score']}</td></tr>
              <tr><td>Sleep disturbances</td><td>{participantInfo.customFields['PSQI_sleepDisturbances_score']}</td></tr>
              <tr><td>Use of sleeping medication</td><td>{participantInfo.customFields['PSQI_useOfSleepingMedication_score']}</td></tr>
              <tr><td>Daytime dysfunction</td><td>{participantInfo.customFields['PSQI_daytimeDisfunction_score']}</td></tr>
            </tbody>
            </table>
        </div>

        <div className='report-section'>
            <div><b>10. The Newest Vital Sign</b></div>
            <table>
                <tr>
                  <td>Last Completed:  {surveyAnswerTimestamp('NVS_score-NVS_score').toDateString()}</td><td>Score: {participantInfo.customFields['NVS_score']}</td>
                </tr>
            </table>
        </div>

        <div className='report-section'>
            <div><b>11. WHODAS 2.0</b></div>
            <table>
                <tr>
                  <td>Last Completed:  {surveyAnswerTimestamp('WHODAS_score-WHODAS_score').toDateString()}</td>
                </tr>
                <tr>
                    <td>Goal(s) Triggered: {getWHODASGoals().join(', ')}</td>
                </tr>
            </table>
          <table className='whodas-table' border={1}>
            <thead>
              <tr>
                <th>
                  Domain
                </th>
                <th>
                  Score
                </th>
              </tr>
            </thead>
            <tbody>
              <tr><td>Overall</td><td>{participantInfo.customFields['WHODAS_score']}</td></tr>
              <tr><td>Cognition</td><td>{participantInfo.customFields['WHODAS_cognition_score']}</td></tr>
              <tr><td>Mobility</td><td>{participantInfo.customFields['WHODAS_mobility_score']}</td></tr>
              <tr><td>Self-Care</td><td>{participantInfo.customFields['WHODAS_selfCare_score']}</td></tr>
              <tr><td>Getting Along</td><td>{participantInfo.customFields['WHODAS_gettingAlong_score']}</td></tr>
              <tr><td>Life Activities</td><td>{participantInfo.customFields['WHODAS_lifeActivities_score']}</td></tr>
              <tr><td>Participant in Society</td><td>{participantInfo.customFields['WHODAS_participationInSociety_score']}</td></tr>
            </tbody>
            </table>
        </div>

        <div className='report-section'>
          <div><b>12. 5F-WEL</b></div>
          <table>
            <tr>
              <td>Last Completed:  {surveyAnswerTimestamp('TotalWellness-TotalWellness').toDateString()}</td>
            </tr>
            <tr>
              <td>Goal(s) Triggered: {getFFWELGoals().join(', ')}</td>
            </tr>
          </table>
          {ffwelModel &&
            <div className='fwel-report'>
              <FFWELSummaryTable model={ffwelModel} showReference={true} />

              <table>
                <thead>
                  <tr>
                    <th>≥ 90</th>
                    <th>(Mean-2SD) - 90</th>
                    <th>&lt; 2 SD below mean</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <b>Score Legend</b>
                    </td>
                    <td className='optimal'>
                      Optimal wellness level.
                      Monitor and maintain
                    </td>
                    <td className='warning'>
                      Action is recommended to
                      achieve optimal wellness.
                    </td>
                    <td className='action'>
                      Immediate action recommended. 97.5%
                      of the population have higher scores
                    </td>
                  </tr>
                </tbody>
              </table>

              <FFWELBreakdownTable model={ffwelModel} showReference={true} />
            </div>
          }
        </div>

        <div className='report-section'>
          <div><b>13. Fitbit Data</b></div>
          <table>
            <thead>
              <tr>
                <th></th>
                <th>Average over past month</th>
                <th>Delta from past month</th>
                <th>Average over last week</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Resting Heart Rate</td>
                <td>{rhrMonthAvg.toFixed(2)}</td>
                <td>{rhrDelta.toFixed(2)}</td>
                <td>{rhrWeekAvg.toFixed(2)}</td>
              </tr>
              <tr>
                <td>Steps</td>
                <td>{stepMonthAvg.toFixed(2)}</td>
                <td>{stepDelta.toFixed(2)}</td>
                <td>{stepWeekAvg.toFixed(2)}</td>
              </tr>
              <tr>
                <td>Sleep</td>
                <td>{(sleepMonthAvg / 60).toFixed(2)}</td>
                <td>{(sleepDelta / 60).toFixed(2)}</td>
                <td>{(sleepWeekAvg / 60).toFixed(2)}</td>
              </tr>
              <tr>
                <td>Active Minutes</td>
                <td>{activeMonthAvg.toFixed(2)}</td>
                <td>{activeDelta.toFixed(2)}</td>
                <td>{activeWeekAvg.toFixed(2)}</td>
              </tr> 
              <tr>
                <td>Calories</td>
                <td>{caloriesMonthAvg.toFixed(2)}</td>
                <td>{caloriesDelta.toFixed(2)}</td>
                <td>{caloriesWeekAvg.toFixed(2)}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className='report-section'>
          <div><b>Suggested Goals</b></div>
          <table>
            <thead>
              <tr>
                <th>Goal</th>
                <th>Suggested</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  Improve your stress management
                </td>
                <td>
                  {participantInfo.customFields['zSG_improveYourStressManagement']}
                </td>
              </tr>
              <tr>
                <td>
                  Improve your sleep
                </td>
                <td>
                  {participantInfo.customFields['zSG_improveYourSleep']}
                </td>
              </tr>
              <tr>
                <td>
                  Quit smoking
                </td>
                <td>
                  {participantInfo.customFields['zSG_quitSmoking']}
                </td>
              </tr>
              <tr>
                <td>

                  Cut back on substance use
                </td>
                <td>
                  {participantInfo.customFields['zSG_cutBackOnSubstanceUse']}
                </td>
              </tr>
              <tr>
                <td>
                  Improve your physical wellness
                </td>
                <td>
                  {participantInfo.customFields['zSG_improveYourPhysicalWellness']}
                </td>
              </tr>
              <tr>
                <td>

                  Improve your love wellness
                </td>
                <td>
                  {participantInfo.customFields['zSG_improveYourLoveWellness']}
                </td>
              </tr>
              <tr>
                <td>
                  Increase your work wellness
                </td>
                <td>
                  {participantInfo.customFields['zSG_increaseYourWorkWellness']}
                </td>
              </tr>
              <tr>
                <td>
                  Increase your friendship wellness
                </td>
                <td>
                  {participantInfo.customFields['zSG_increaseYourFriendshipWellness']}
                </td>
              </tr>
              <tr>
                <td>

                  Improve your nutrition wellness
                </td>
                <td>
                  {participantInfo.customFields['zSG_improveYourNutritionWellness']}
                </td>
              </tr>
              <tr>
                <td>
                  Improve your thinking
                </td>
                <td>
                  {participantInfo.customFields['zSG_improveYourThinking']}
                </td>
              </tr>
              <tr>
                <td>
                  Improve the experiencing of your emotions
                </td>
                <td>
                  {participantInfo.customFields['zSG_improveTheExperiencingOfYourEmotions']}
                </td>
              </tr>
              <tr>
                <td>
                  Increase your leisure wellness
                </td>
                <td>
                  {participantInfo.customFields['zSG_increaseYourLeisureWellness']}
                </td>
              </tr>
              <tr>
                <td>
                  Increase your sense of control
                </td>
                <td>
                  {participantInfo.customFields['zSG_increaseYourSenseOfControl']}
                </td>
              </tr>
              <tr>
                <td>
                  Increase your positive humour
                </td>
                <td>
                  {participantInfo.customFields['zSG_increaseYourPositiveHumour']}
                </td>
              </tr>
              <tr>
                <td>
                  Increase your self worth
                </td>
                <td>
                  {participantInfo.customFields['zSG_increaseYourSelfWorth']}
                </td>
              </tr>
              <tr>
                <td>
                  Improve your realistic beliefs
                </td>
                <td>
                  {participantInfo.customFields['zSG_improveYourRealisticBeliefs']}
                </td>
              </tr>
              <tr>
                <td>
                  Increase your spiritual wellness
                </td>
                <td>
                  {participantInfo.customFields['zSG_increaseYourSpiritualWellness']}
                </td>
              </tr>
              <tr>
                <td>
                  Enhance your gender identity
                </td>
                <td>
                  {participantInfo.customFields['zSG_enhanceYourGenderIdentity']}
                </td>
              </tr>
              <tr>
                <td>
                  Enhance your cultural identity
                </td>
                <td>
                  {participantInfo.customFields['zSG_enhanceYourCulturalIdentity']}
                </td>
              </tr>
              <tr>
                <td>
                  Improve your self care
                </td>
                <td>
                  {participantInfo.customFields['zSG_improveYourSelfCare']}
                </td>
              </tr>
            </tbody>
          </table>

        </div>

        <div className='report-section'>
          <div><b>Selected Goals</b></div>
          <div>Last Complete: {surveyAnswerTimestamp('GOALS_01-GOALS_01').toDateString()}</div>
          <div>{surveyAnswersOrEmpty('GOALS_01-GOALS_01')}</div>
        </div>
    </div>
   </Layout>
  );
}