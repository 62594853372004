import { TextBlock } from "@careevolution/mydatahelps-ui";
import { StringDictionary } from "./GoalHub";

import './SMARTResults.css';
import { FontAwesomeSvgIcon } from "react-fontawesome-svg-icon";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { useState } from "react";

export interface SMARTResultsProperties {
	header?: string,
	smartAnswers: StringDictionary | null
}


export default function(props: SMARTResultsProperties) {
	var [showPopper, setShowPopper] = useState(false);
	return (
		<>
			{props.header && 
				<TextBlock className="section-subheader">
					<span>Most recent SMART goal for {props.header}</span>
					<div className="popover-container">
						<FontAwesomeSvgIcon className="smart-popover-icon" icon={faInfoCircle} onMouseEnter={() => setShowPopper(true)} onMouseLeave={() => setShowPopper(false)} />
						<div className="smart-popover" style={{ display: showPopper ? 'block' : 'none' }}>
							<strong>S</strong>pecific <br /> <strong>M</strong>easurable <br /> <strong>A</strong>ttainable <br /> <strong>R</strong>ealistic <br /> <strong>T</strong>imely
						</div>
					</div>
				</TextBlock>
			}



			<TextBlock className="smart-results-container">
				<div className="smart-results-block">
					{props.smartAnswers == null &&
						<div>It looks like you haven't set a SMART goal yet! To do so, please review the learning module on this topic</div>
					}
					{props.smartAnswers !== null &&
						<>
							<div className="smart-result"> S - {props.smartAnswers!['Specific']}</div>
							<div className="smart-result"> M - {props.smartAnswers!['Measurable']}</div>
							<div className="smart-result"> A - {props.smartAnswers!['Attainable']}</div>
							<div className="smart-result"> R - {props.smartAnswers!['Relevant']}</div>
							<div className="smart-result"> T - {props.smartAnswers!['Timely']}</div>
						</>
					}
				</div>
			</TextBlock>
		</>
	);
}